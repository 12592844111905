import api from "@/api";

const getters = {};
const state = {};
const mutations = {};

export const TRACKING = "tracking";
export const DELIVERY = "delivery";

const actions = {
  [TRACKING](context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("/tracking", payload)
        .then((r) => {
          // context.commit(SET_ORDER_ID, r.data.id);
          resolve(r.data);
        })
        .catch((r) => {
          reject(r);
        });
    });
  },
  [DELIVERY](context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("/get.delivery", payload)
        .then((r) => {
          resolve(r.data);
        })
        .catch((r) => {
          reject(r);
        });
    });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
