import api from "@/api";

const getters = {};

const state = {};

const mutations = {};

export const LOAD_METRICS = "loadMetrics";

const actions = {
  [LOAD_METRICS]() {
    return new Promise((resolve, reject) => {
      api
        .post("/metrics")
        .then((r) => {
          resolve(r.data);
        })
        .catch((r) => {
          reject(r);
        });
    });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
