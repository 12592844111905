<template>
  <div v-if="item.component" :id="'s-' + item.hash.name">
    <component :is="item.component"></component>
  </div>
  <div
    :id="'s-' + item.hash.name"
    class="bg-image"
    :class="{ full: item.useFullBackground }"
    :style="
      item.useFullBackground
        ? 'background-image: url(' + item.background.image + ');'
        : ''
    "
    v-if="!item.component"
  >
    <div class="container d-md-flex align-items-center container-padding">
      <div
        class="row align-items-center justify-content-between d-xxxxxxxxxxl-contents"
        :class="{ 'justify-content-end': odd }"
      >
        <div
          class="d-none d-md-flex col-md-6 justify-content-center"
          v-if="odd"
        >
          <img
            v-if="!item.useFullBackground"
            v-lazy="{ src: item.background.image }"
            class="image-mb"
            :style="
              'width:' + item.background.size + ';' + item.background.correct
            "
            alt=""
          />
        </div>
        <div class="col-12" :class="'col-md-' + colMd">
          <paragraph
            :title="item.title"
            :odd="odd"
            :subtitle="item.subtitle"
            :background="item.useFullBackground"
          >
            <div class="d-flex d-md-none justify-content-center">
              <img
                v-lazy="{
                  src: item.image_mobile,
                }"
                alt=""
                :style="item.image_mobile_correct"
                class="img-mobile"
              />
            </div>
            <hide-text :title="item.title">
              <div v-if="item.text instanceof Object">
                <component :is="item.text" />
              </div>
              <div v-html="item.text" v-else></div>
            </hide-text>
          </paragraph>
        </div>
        <div
          class="col-md-6 d-none d-md-flex justify-content-center"
          v-if="!odd"
        >
          <img
            v-if="!item.useFullBackground"
            v-lazy="{ src: item.background.image }"
            class="image-mb"
            :style="
              'width:' + item.background.size + ';' + item.background.correct
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ModalText from "@/pages/Main/ui/ModalText";
import Paragraph from "@/pages/Main/ui/Paragraph";
import HideText from "@/pages/Main/ui/HideText";

export default {
  name: "MainBlock",
  components: {
    HideText,
    // ModalText,
    Paragraph,
  },
  props: {
    odd: {
      default: true,
    },
    colMd: {
      default: 6,
    },
    item: {
      default: {},
      required: true,
    },
  },
  // computed: {
  //     var path = require('path');
  // }
};
</script>

<style lang="scss" scoped>
.bg-image {
  position: relative;
  &.full {
    @media (min-width: 768px) {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      //    &:before {
      //        content: "";
      //        z-index: -1;
      //        position: absolute;
      //        background-image: var(--background);
      //        background-repeat: no-repeat;
      //        background-position: var(--background-position);
      //        background-size: var(--background-size);
      //        height: 100%;
      //        width: 100%;
      //        top: 0;
      //    }
    }
    @media (min-width: 991px) {
      .container {
        height: 1000px;
      }
    }
    @media (min-width: 1921px) {
      .container {
        height: 1300px;
      }
    }
    @media (max-width: 767px) {
      background: none !important;
    }
  }
}
.img-mobile {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  max-height: 100%;
}

.d-xxxxxxxxxxl-contents {
  @media (min-width: 1921px) {
    display: contents;
  }
}
//.image-mb {
//    @media (max-width: 1200px) {
//        height: 460px;
//        width: auto !important;
//    }
//}
</style>
