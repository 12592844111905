import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/pages/Main/MainPage";
import scrollToElement from "@/functions/scroll";
// import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "main",
    component: MainPage,
    meta: {
      title: "Главная - DOMSPIRT",
      metaTags: [
        {
          name: "description",
          content: "Официальный сайт DOMSPIRT.",
        },
        {
          property: "og:description",
          content: "Официальный сайт DOMSPIRT.",
        },
      ],
    },
  },
  {
    path: "/characteristics",
    name: "characteristics",
    component: () =>
      import(
        /* webpackChunkName: "characteristics" */ "../pages/Characteristics/CharacteristicsPage"
      ),
    meta: {
      title: "Характеристики - DOMSPIRT",
      metaTags: [
        {
          name: "description",
          content: "Официальный сайт DOMSPIRT.",
        },
        {
          property: "og:description",
          content: "Официальный сайт DOMSPIRT.",
        },
      ],
    },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () =>
      import(/* webpackChunkName: "reviews" */ "../pages/Reviews/ReviewsPage"),
    meta: {
      title: "Отзывы - DOMSPIRT",
      metaTags: [
        {
          name: "description",
          content: "Официальный сайт DOMSPIRT.",
        },
        {
          property: "og:description",
          content: "Официальный сайт DOMSPIRT.",
        },
      ],
    },
  },
  // {
  //     path: "/deliveries",
  //     name: "deliveries",
  //     component: () =>
  //         import(
  //             /* webpackChunkName: "deliveries" */ "../pages/Deliveries/DeliveriesPage"
  //         ),
  //     meta: {
  //         title: "Доставка - DOMSPIRT",
  //         metaTags: [
  //             {
  //                 name: "description",
  //                 content: "Официальный сайт DOMSPIRT.",
  //             },
  //             {
  //                 property: "og:description",
  //                 content: "Официальный сайт DOMSPIRT.",
  //             },
  //         ],
  //     },
  // },
  {
    path: "/contacts",
    name: "contacts",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "../pages/Contacts/ContactsPage"
      ),
    meta: {
      title: "Контакты - DOMSPIRT",
      metaTags: [
        {
          name: "description",
          content: "Официальный сайт DOMSPIRT.",
        },
        {
          property: "og:description",
          content: "Официальный сайт DOMSPIRT.",
        },
      ],
    },
  },
  // {
  //   path: "/editor",
  //   name: "editor",
  //   component: () =>
  //     import(/* webpackChunkName: "editor" */ "../pages/Editor/EditorPage"),
  //   meta: {
  //     title: "Редактор - DOMSPIRT",
  //     metaTags: [
  //       {
  //         name: "description",
  //         content: "Официальный сайт DOMSPIRT.",
  //       },
  //       {
  //         property: "og:description",
  //         content: "Официальный сайт DOMSPIRT.",
  //       },
  //     ],
  //   },
  // },
  /*{
        path: "/comparison",
        name: "comparison",
        component: () =>
            import(
                /!* webpackChunkName: "comparison" *!/ "../pages/Comparison/ComparisonPage"
            ),
        meta: {
            title: "Сравнение - DOMSPIRT",
            metaTags: [
                {
                    name: "description",
                    content: "Официальный сайт DOMSPIRT.",
                },
                {
                    property: "og:description",
                    content: "Официальный сайт DOMSPIRT.",
                },
            ],
        },
    },*/
];

const router = createRouter({
  mode: "history",
  history: createWebHistory("/"),
  scrollBehavior: function (to, from, savedPosition) {
    /*return new Promise((resolve) => {
            if (to.hash) {
                setTimeout(() => {
                    resolve({ el: to.hash });
                }, 1000);
            } else if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        });*/
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ""));
        if (element) {
          scrollToElement(element);
        }
        // if (element && element.scrollIntoView) {
        //   console.log(elementInViewport(element));
        //   element.scrollIntoView({
        //     block: "start",
        //     behavior: "smooth",
        //   });
        //
        //   setTimeout(() => {
        //     element.scrollIntoView({
        //       block: "start",
        //       behavior: "smooth",
        //     });
        //   }, 600);
        // }
      }, 300);

      //NOTE: This doesn't work for Vue 3
      //return {selector: to.hash}

      //This does
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
  routes,
});

// router.beforeEach((to, from, next) => {
//     next();
//     // Scroll page to top on every route change
//     setTimeout(() => {
//         window.scrollTo({
//             top: 0,
//             left: 0,
//             behavior: "instant",
//         });
//         // document.documentElement.scrollTop = 0;
//     }, 10);
// });

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
