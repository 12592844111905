if (window.webpSupport == undefined) {
  // console.log("check webp support");
  window.webpSupport = true;
  try {
    const elem = document.createElement("canvas");

    if (elem.getContext && elem.getContext("2d")) {
      window.webpSupport =
        elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
  } catch (err) {
    window.webpSupport = false;
  }
}

const webp = function (src) {
  // console.log(src);
  if (window.webpSupport) {
    return src + ".webp";
  } else {
    return src;
  }
};

export { webp };
