import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2558,
  sort_id: 201,
  display: true,
  hide_mobile: false,
  subitem: 2550,
  metrikaGoal: "module",
  name: {
    full: "Увеличитель куба +20 л ",
    short: "Увел-ль + 20 л",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/uvelichitel/html"))
  ),
  price: 3990,
  layers: {
    0: [],
    1: [
      // {
      //   layer: 52,
      //   name: "size",
      //   url: require("@/pages/Main/assets/order4/size.png") + webp(),
      // },
      {
        layer: 52,
        name: "size_zoom",
        hide: [],
        url: require("@/pages/Main/assets/order5/size.png") + webp(),
      },
      {
        layer: 52,
        name: "size",
        hide: [],
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/size.png") + webp(),
      },
    ],
  },
  image: {
    url: "",
    layer: 1,
  },
  count: 0,
  active: false,
};
