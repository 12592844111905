import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 1707,
  sort_id: 207,
  display: true,
  subitem: 2550,
  metrikaGoal: "perehod",
  name: {
    full: 'Переходник с 3" на 2"',
    short: 'Переходник на 2"',
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/perehod/html"))
  ),
  price: 990,
  layers: {
    "*": [
      {
        layer: 56,
        name: "glass_zoom",
        url: require("@/pages/Main/assets/order5/perehod.png"),
      },
      {
        layer: 56,
        name: "glass",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/perehod.png"),
      },
    ],
  },
  count: 1,
  active: false,
};
