<template>
  <div class="hide-text-container mt-4" v-if="mobile" :class="{ show: show }">
    <div class="hide-text">
      <div class="full-text">
        <slot></slot>
      </div>
      <div class="button">
        <button @click="toggleText()">
          {{ show ? "Скрыть" : "Подробнее" }}
          <img v-lazy="{ src: require('../assets/caret-down.svg') }" alt="" />
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HideText",
  data() {
    return {
      show: false,
      mobile: false,
    };
  },
  methods: {
    toggleText() {
      if (this.show === true) {
        this.hideText();
      } else {
        this.showText();
      }
    },
    showText() {
      this.show = true;
    },
    hideText() {
      this.show = false;
    },
  },
  mounted() {
    if (window.innerWidth < 768) this.mobile = true;
  },
};
</script>

<style lang="scss">
.hide-text-container {
  height: 150px;

  .hide-text {
    position: absolute;
    bottom: 0;
    padding-top: 130px;
    transition: all 0.5s;
    left: -1.5rem;
    right: -1.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0, 0) 100%
    );

    .full-text {
      z-index: 1;
      position: relative;

      background-image: linear-gradient(
        to bottom,
        #fff 0%,
        transparent 50%,
        transparent 100%
      );
      color: transparent;
      /* purgecss start ignore */
      background-clip: text;
      -webkit-background-clip: text;
      text-shadow: 0 0 transparent;
      /* purgecss end ignore */
      max-height: 150px;
      transition: all 0.5s;

      a {
        display: none;
      }
    }
  }

  .button {
    //background: linear-gradient(
    //    to bottom,
    //    rgba(0, 0, 0, 0) 10%,
    //    rgba(0, 0, 0, 1) 70%,
    //    rgba(0, 0, 0, 1) 100%
    //);
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: end;
    transition: all 0.3s;
    button {
      display: flex;
      align-items: center;
      border: 1px solid #2a93f4;
      background: transparent;
      box-sizing: border-box;
      border-radius: 100px;
      color: #2a93f4;
      font-weight: 500;
      font-size: 16px;
      padding: 9px 25px;
      margin-bottom: 20px;
      z-index: 2;

      img {
        transition: all 0.3s;
        margin-left: 10px;
      }
    }
  }

  &.show {
    .hide-text {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 50%,
        rgb(0, 0, 0) 80%,
        rgb(0, 0, 0, 0) 100%
      );
      padding-top: 130px;
      .full-text {
        color: #fff;
        max-height: 800px;
        padding-bottom: 90px;
        a {
          display: inline-block;
        }
      }
    }
  }
}
/*.hide-text {
    position: relative;
    max-height: 180px;
    overflow: hidden;
    //margin: 0 -24px;
    transition: all 0.5s;

    .button {
        //background: linear-gradient(
        //    to bottom,
        //    rgba(0, 0, 0, 0) 10%,
        //    rgba(0, 0, 0, 1) 70%,
        //    rgba(0, 0, 0, 1) 100%
        //);
        margin-top: -60px;
        display: flex;
        justify-content: center;
        align-items: end;
        transition: all 0.3s;
        button {
            display: flex;
            align-items: center;
            border: 1px solid #2a93f4;
            background: transparent;
            box-sizing: border-box;
            border-radius: 100px;
            color: #2a93f4;
            font-weight: 500;
            font-size: 16px;
            padding: 9px 25px;
            margin-bottom: 20px;

            img {
                transition: all 0.3s;
                margin-left: 10px;
            }
        }
    }
    .full-text {
        z-index: -1;
        position: relative;

        background-image: linear-gradient(
            to bottom,
            #fff 0%,
            transparent 50%,
            transparent 100%
        );
        color: transparent;
        background-clip: text;
        max-height: 150px;
        transition: all 0.5s;
    }

    &.show {
        max-height: 800px;
        margin: 0;

        .button {
            margin-top: 0;
            //opacity: 0;
            img {
                transform: rotateZ(180deg);
            }
        }

        .full-text {
            color: #fff;
            max-height: 800px;
        }
    }
}*/
</style>
