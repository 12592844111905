const inBrowser = typeof window !== "undefined" && window !== null;

const webp = function () {
  if (!inBrowser) return false;

  let support = true;

  try {
    const elem = document.createElement("canvas");

    if (elem.getContext && elem.getContext("2d")) {
      support = elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
  } catch (err) {
    support = false;
  }

  return support ? ".webp" : "";
};

export default webp;
