<template>
  <!--  <p>
    Мировые производители виски и коньяка используют аппараты простой перегонки
    из меди (<a
      href="javascript:void(0)"
      @click.prevent="$root.proxy.AppFooter.$refs.modalWhisky.open()"
      class="link"
      >фото</a
    >). Медь улучшает аромат любого алкоголя за счёт связывания сернистых
    соединений (<a
      href="javascript:void(0)"
      @click.prevent="$root.proxy.AppFooter.$refs.modalSpirt.open()"
      class="link"
      >ссылка на исследование</a
    >).
  </p>
  <p>
    При колонной дистилляции (не простой перегонке) серу, теоретически, можно
    отделить и на нержавеющих тарелках, если их 6 и более. Поэтому если Вы
    начинающий и сомневаетесь, выбрать медь или нержавейку, дешевле (если
    ограничен бюджет) выбрать нержавеющие тарелки, а в будущем, когда уже
    «втянетесь», приобрести медные. Для дистиллятов (не для спирта!) более 6
    тарелок не используют, т.к. аромат при этом теряется.
  </p>-->

  <p>
    Медь улучшает аромат алкоголя за счёт связывания сернистых соединений -
    доказано учёными Эдинбургского университета (<a
      href="javascript:void(0)"
      @click.prevent="$root.proxy.AppFooter.$refs.modalSpirt.open()"
      class="link"
      >исследование</a
    >). Соединения серы образуются при варке белка и имеют запах варёных яиц,
    явно различаемый в «деревенском» самогоне. Белок попадает в брагу: 1) из
    дрожжей. Дрожжи - это грибы, и как и всё живое состоят из белков. 2) из
    клеток злаков или фруктов в зерновой или плодовой браге. При перегонке на
    меди алкоголь имеет гораздо более благородный аромат.
  </p>

  <p>
    Мировые производители виски и коньяка: Jameson, Hennessy, Baccardi, Jack
    Daniels - используют аппараты из меди (<a
      href="javascript:void(0)"
      @click.prevent="$root.proxy.AppFooter.$refs.modalWhisky.open()"
      class="link"
      >фото</a
    >).
  </p>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";

const BASE_URL =
  process.env.NODE_ENV === "production" ? process.env.BASE_URL : "";

export default {
  name: "MedText",
  components: {},
  data: () => ({
    BASE_URL,
  }),
  methods: {
    openVideo(src) {
      function gcd(a, b) {
        return b == 0 ? a : gcd(b, a % b);
      }

      this.getVideoDimensionsOf(src).then((video) => {
        let w = video.width;
        let h = video.height;
        let r = gcd(w, h);

        Fancybox.show(
          [
            {
              src: src,
              ratio: w / r / (h / r),
              width: video.width,
              height: video.height,
            },
          ],
          {
            preload: 0,
          }
        );
      });
    },
    getVideoDimensionsOf(url) {
      return new Promise((resolve) => {
        // create the video element
        const video = document.createElement("video");

        // place a listener on it
        video.addEventListener(
          "loadedmetadata",
          () => {
            // retrieve dimensions
            const height = video.videoHeight;
            const width = video.videoWidth;

            // send back result
            resolve({ height, width });
          },
          false
        );

        // start download meta-datas
        video.src = url;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #488eff;
  text-decoration: underline;
}
</style>
