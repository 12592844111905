<template>
  <div id="track">
    <div class="delivery-tracking">
      <div class="row">
        <div class="col-12 col-md">
          <input
            type="text"
            v-model="form.order_id"
            placeholder="Введите номер заказа"
          />
        </div>
        <div class="col-12 col-md">
          <input
            type="text"
            v-model="form.phone"
            placeholder="Введите телефон"
          />
        </div>
        <div class="col-12 col-md-3">
          <button :disabled="!trackingAllowed" @click="track()">
            Отследить
          </button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="tracking">
        <h3 class="text-center mt-5 text-muted">Поиск...</h3>
      </div>
      <div v-else>
        <div v-if="info === false">Данные не найдены</div>
        <!--        <div v-else-if="info === null">Ошибка</div>-->
        <div v-else>
          <div v-if="info && info.error">
            <h3 class="text-center mt-5 text-muted">{{ info.error }}</h3>
          </div>
          <div v-else-if="info">
            <timeline-delivery :order="info.order" :delivery="info.delivery" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TRACKING } from "@/store/modules/tracking";
import TimelineDelivery from "@/pages/Deliveries/ui/TimelineDelivery";

export default {
  name: "DeliveryTracking",
  components: {
    TimelineDelivery,
  },
  data() {
    return {
      mobile: false,
      tracking: false,
      info: null,
      form: {
        phone: "",
        order_id: "",
      },
    };
  },
  computed: {
    trackingAllowed() {
      return (
        !this.tracking &&
        this.form.phone.length > 10 &&
        this.form.order_id.length > 4
      );
    },
  },
  methods: {
    track() {
      // this.$refs.modalTracking.open();
      if (this.trackingAllowed) {
        this.tracking = true;
        this.$store.dispatch(TRACKING, this.form).then((data) => {
          this.tracking = false;
          this.info = data.response;
        });
      }
    },
  },
  mounted() {
    if (window.innerWidth < 768) this.mobile = true;
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 40px;
}
.delivery-box {
  @media (max-width: 768px) {
    padding: 32px 24px;
    background: linear-gradient(
      129.22deg,
      rgba(21, 21, 22, 0.9) 0%,
      rgba(50, 50, 51, 0.9) 97.03%
    );
    border-radius: 12px;
  }
}
.delivery-tracking {
  textarea {
    background: #72727263;
    border-radius: 10px;
    padding: 12px 50px 12px 20px;
    color: #fff;
    font-size: 18px;
    //border: none;
    width: 100%;
    resize: none; /* Запрещаем изменять размер */
  }
  input {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 12px 50px 12px 20px;
    color: #505050;
    font-size: 18px;
    //border: none;
    width: 100%;
    margin-bottom: 15px;
  }
  button {
    padding: 12px 0px 11px;
    background: #2a93f4;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    border: none;
    width: 100%;
    color: #fff;

    &[disabled] {
      background: #818181;
    }
  }
}
</style>
