import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2203,
  sort_id: 102,
  display: true,
  subitem: 2554,
  metrikaGoal: "glass",
  name: {
    full: "Запасное стекло",
    short: "Запасное стекло",
  },
  component: markRaw(
    defineAsyncComponent(() =>
      import("@/store/modules/items/zapasnoe-steklo/html")
    )
  ),
  price: 990,
  layers: {
    "*": [
      {
        layer: 56,
        name: "glass_zoom", // <-
        url: require("@/pages/Main/assets/order5/glass.png"),
      },
      {
        layer: 56,
        name: "glass", // ->
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/glass.png"),
      },
    ],
  },
  count: 1,
  active: false,
};
