<template>
  <app-layout container-class="container-fluid overflow-x-hidden px-md-0">
    <div id="fullpage">
      <div
        class="section"
        :id="item.hash.name"
        :key="item.title"
        v-for="item in items"
        :class="{
          'pt-0': item.sectionPadding && item.sectionPadding.top,
          'pb-0': item.sectionPadding && item.sectionPadding.bottom,
        }"
      >
        <main-block
          :item="item"
          :odd="item.position % 2 === 0"
          :col-md="item.colMd || 6"
        />
      </div>
    </div>
    <modal-item />
  </app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";

// import fullpage from "fullpage.js";
import MainBlock from "@/pages/Main/components/MainBlock";
import ModalItem from "@/pages/Main/ui/ModalItem";
import { defineAsyncComponent, markRaw } from "vue";
import { UPDATE_USER_HISTORY } from "@/store/modules/user";
// require("fullpage.js/dist/fullpage.css");

import StartBlockMobile from "@/pages/Main/Mobile/StartBlock";
import StartBlockDesktop from "@/pages/Main/Desktop/StartBlock";
import ClearSpirtText from "@/pages/Main/text/ClearSpirtText";
import WhiskyBrandiText from "@/pages/Main/text/WhiskyBrandiText";
import AestheticText from "@/pages/Main/text/AestheticText";
import MedText from "@/pages/Main/text/MedText";

export default {
  name: "MainPage",
  components: {
    ModalItem,
    MainBlock,
    AppLayout,
  },
  data: function () {
    return {
      // fullpage: null,
      webp: true,
      items: [],
      isMobile: true,
      isTablet: true,
    };
  },
  methods: {
    // getAnchorsURL() {
    //     let section;
    //     let slide;
    //     const hash = window.location.hash;
    //
    //     if (hash.length) {
    //         //getting the anchor link in the URL and deleting the `#`
    //         const anchorsParts = hash.replace("#", "").split("/"); //using / for visual reasons and not as a section/slide separator #2803
    //
    //         const isFunkyAnchor = hash.indexOf("#/") > -1;
    //         section = isFunkyAnchor
    //             ? "/" + anchorsParts[1]
    //             : decodeURIComponent(anchorsParts[0]);
    //         const slideAnchor = isFunkyAnchor
    //             ? anchorsParts[2]
    //             : anchorsParts[1];
    //
    //         if (slideAnchor && slideAnchor.length) {
    //             slide = decodeURIComponent(slideAnchor);
    //         }
    //     }
    //
    //     return {
    //         section: section,
    //         slide: slide,
    //     };
    // },
    checkWebp() {
      let support = true;

      try {
        const elem = document.createElement("canvas");

        if (elem.getContext && elem.getContext("2d")) {
          support =
            elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        }
      } catch (err) {
        support = false;
      }

      this.webp = support;
    },
  },
  beforeMount() {
    this.isMobile = window.innerWidth < 768;
    this.isTablet = window.innerWidth < 992;
  },
  mounted() {
    this.checkWebp();
    this.$store.dispatch(UPDATE_USER_HISTORY);
    // let self = this;
    // if (window.innerWidth > 768) {
    //     // self.fullpage = new fullpage("#fullpage", {
    //     //     navigation: true,
    //     //     navigationTooltips: this.items.map((v) => v.hash.text),
    //     //     anchors: this.items.map((v) => v.hash.name),
    //     //     /*navigationTooltips: [
    //     //         "Домашний спиртзавод",
    //     //         "Чистый спирт",
    //     //         "Ароматные дистилляты",
    //     //         "Легкое управление",
    //     //         "Не нужны доп. емкости",
    //     //         "Мощность охлаждения - 7 кВт",
    //     //         "Оформить заказ",
    //     //     ],
    //     //     anchors: [
    //     //         "main",
    //     //         "spirt",
    //     //         "dist",
    //     //         "easy",
    //     //         "noneed",
    //     //         "strong",
    //     //         "strong-tank",
    //     //         "for-life",
    //     //         "strong-security",
    //     //         "unlimited",
    //     //         "recept",
    //     //         "nastoiki",
    //     //         "equipment",
    //     //         "order",
    //     //         "footer",
    //     //     ],*/
    //     //     showActiveTooltip: true,
    //     //     // responsiveSlides: true,
    //     //     credits: {
    //     //         enabled: false,
    //     //         label: "Made with fullPage.js",
    //     //         position: "right",
    //     //     },
    //     //
    //     //     afterRender: function () {
    //     //         let fp = self.getAnchorsURL();
    //     //         if (fp.section && fp.section.length) {
    //     //             window.fullpage_api.silentMoveTo(fp.section);
    //     //         } else {
    //     //             window.fullpage_api.silentMoveTo("main");
    //     //         }
    //     //     },
    //     // });
    // }

    // this.isMobile = window.innerWidth < 768;
    // console.log(this.isMobile, window.innerWidth);

    this.items = [
      {
        component: this.isTablet ? StartBlockMobile : StartBlockDesktop,
        hash: {
          name: "main",
          text: "Домашиний спиртзавод",
        },
        sectionPadding: {
          top: true,
          bottom: true,
        },
      },
      {
        position: 1,
        title: "Прозрачная гарантия",
        hash: {
          name: "clear",
          text: "Прозрачная гарантия крепости",
        },
        subtitle: "Чистоты и крепости 96% и выше",
        text: ClearSpirtText,
        // image_desktop: require("./assets/clear-spirt.png"),
        animated: false,
        image_mobile: require("./assets/full_bg/clear-spirt_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -80px; margin-top: -150px; width: 100vw;",
        // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
        background: {
          image: this.webp
            ? require("./assets/full_bg/clear-spirt.webp")
            : require("./assets/full_bg/clear-spirt.png"),
          // image: require("./assets/full_bg/clear-spirt.jpg"),
          position: "left 32% center",
          size: "24vw",
          correct: "margin-top: 80px;",
        },
        useFullBackground: true,
        sectionPadding: {
          top: true,
        },
      },
      /*{
                position: 0,
                title: "Чистый спирт 96% об.",
                hash: {
                    name: "clear",
                    text: "Чистый спирт",
                },
                subtitle: "18 ступеней перегонки",
                text: '<p>18 ступеней перегонки DomSpirt гарантируют крепость дистиллята 96,3% в базе и 96,9% с доп. царгой. 3" колонна работает на мощности до 3.5 кВт, обеспечивая скорость отбора в 2 раза выше 2"-конкурентов.</p><p>Себестоимость спирта из сахара (рецепт: 5 кг сахара/100 г дрожжей/20 л воды) не превышает 50 руб за 0,5 л бутылку при цене на сахар 70 р/кг.</p>',
                // image_desktop: require("./assets/clear-spirt.png"),
                animated: true,
                image_mobile: require("./assets/clear-spirt-animated.webp"),
                // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
                background: {
                    image: require("./assets/clear-spirt-animated.webp"),
                    position: "left 32% center",
                    size: "24vw",
                    correct: "margin-top: 80px;",
                },
            },*/
      {
        position: 3,
        title: "№1 для дистиллятов",
        hash: {
          name: "aroma",
          text: "Виски, бренди, коньяк",
        },
        subtitle: "Виски, бренди, коньяк",
        text: WhiskyBrandiText,
        image_mobile: require("./assets/whisky-brandi_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -80px; margin-top: -30px; width: 100vw;",
        background: {
          image: require("./assets/whisky-brandi.png"),
          position: "right 15% center",
          size: "50vw",
        },
        sectionPadding: {
          bottom: true,
        },
      },
      {
        position: 4,
        title: "Эстетика винокурения",
        hash: {
          name: "life",
          text: "Эстетика винокурения",
        },
        subtitle: "Красота - это тоже немаловажно",
        text: AestheticText,
        // image_mobile: require("./assets/full_bg/for-life_mobile.png"),
        // background: {
        //     image: require("./assets/full_bg/for-life.png"),
        //     position: "left 23% center",
        //     size: "40vw",
        // },
        image_mobile: require("./assets/full_bg/for-life_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -60px; margin-top: -180px; width: 100vw;",
        // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
        background: {
          image: this.webp
            ? require("./assets/full_bg/for-life.webp")
            : require("./assets/full_bg/for-life.png"),
          position: "left 32% center",
          size: "24vw",
          // correct: "margin-top: 80px;",
        },
        useFullBackground: true,
      },
      {
        position: 5,
        title: "Медь М1",
        hash: {
          name: "med_tar",
          text: "Для ароматных дистиллятов",
        },
        subtitle: "Для ароматных дистиллятов",
        text: MedText,
        image_mobile: require("./assets/med_tar.png"),
        background: {
          image: require("./assets/med_tar.png"),
          position: "right 0% center",
          size: "27vw",
        },
        sectionPadding: {
          bottom: true,
        },
      },
      {
        position: 6,
        title: "14 л/час. Мощный",
        hash: {
          name: "fast",
          text: "Простая перегонка до 7 кВт",
        },
        subtitle: "Простая перегонка до 7 кВт",
        text: '<p>Классический виски или коньяк получают простой перегонкой. Все ароматы из ингредиентов (браги) при этом переходят в напиток. Однако и все "резко-самогонные" ароматы тоже! Чтобы улучшить аромат и уменьшить время «варения» дрожжей в кубе, 1 перегонку делают быстро, на мощном источнике нагрева.</p><p>А для быстрого нагрева нужно мощное охлаждение! 2 мощных теплообменника DomSpirt 2 справляются с нагревом до 7 кВт. Димрот из нержегофры (самый эффективный на единицу высоты) эффективно конденсирует пары. А 3-трубный доохладитель быстро охлаждает полученный дистиллят. При нагреве только на плите (или одним тэном) мощные теплообменники в 2-3 раза сократят расход воды и затраты на ЖКХ. Аппарат работает на любых типах плит за счет индукционного 3-слойного дна.</p>',
        // image_mobile: require("./assets/fast-strong_mobile.png"),
        // background: {
        //     image: require("./assets/fast-strong.png"),
        //     position: "left 30% center",
        //     size: "25vw",
        // },
        image_mobile: require("./assets/full_bg/fast-strong_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -80px; margin-top: -100px; width: 100vw;",
        // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
        background: {
          image: this.webp
            ? require("./assets/full_bg/fast-strong.webp")
            : require("./assets/full_bg/fast-strong.png"),
          // image: require("./assets/full_bg/fast-strong.jpg"),
          position: "left 32% center",
          size: "24vw",
          // correct: "margin-top: 80px;",
        },
        useFullBackground: true,
        sectionPadding: {
          top: true,
        },
      },
      {
        position: 7,
        title: "Правильный куб",
        hash: {
          name: "beer",
          text: "Для виски и пива",
        },
        subtitle: "Для виски и пива",
        text: "<p>Для пива нужно залить солод (пророщенное зерно) водой, выдержать нужные термопаузы и добавить хмель. Для вискарной браги  хмель не добавляют. Затем сусло фильтруют.</p><p>Работа с солодом требует точнейшего контроля температуры (иначе ферменты не сработают), быстрого охлаждения после варки (иначе сусло скиснет),  фильтрации и многого другого. Куб  DOMSPIRT разработан для осахаривания солода для виски и пива - все нюансы учтены!</p>",
        image_mobile: require("./assets/strong-tank_mobile.png"),
        background: {
          image: require("./assets/strong-tank.png"),
          position: "right 5% center",
          size: "50vw",
          correct: "margin-top: -70px; margin-bottom: -160px;",
        },
      },
      {
        position: 8,
        title: "Работает везде",
        hash: {
          name: "easy",
          text: "Не нужен ровный пол",
        },
        subtitle: "Не нужен ровный пол. Не нужен стабилизатор напряжения.",
        text: "<p>Насадочные колонны не работают под наклоном, т.к. флегма  при этом стекает ручейком по стенке, а не по насадке. А ведь иногда нужно работать на неровном полу: в гараже, на даче, на балконе, на земле... Работа тарелок не зависит от наклона. Жёстко заданная геометрия обеспечивает одинаковое за счёт определённой высоты перелива количество флегмы и одинаковую крепость при любом наклоне. Не надо ставить колонну по уровню и подвязывать верёвочками!</p><p>Вследствие чёткой геометрии перелива тарелки не требовательны к напряжению, выдавая одинаковую крепость при нагреве от 2.5 до 3.5 кВт. За счёт этого одинаковую крепость можно получить и в новых микрорайонах (до 240 В в розетке) и в дачных кооперативах (иногда менее 195 вольт).</p>",
        // image_mobile: require("./assets/easy-distillation_mobile.png"),
        // background: {
        //     image: require("./assets/easy-distillation.png"),
        //     position: "left 11% center",
        //     size: "55vw",
        // },
        image_mobile: require("./assets/full_bg/easy-distillation_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -80px; margin-top: -180px; width: 100vw;",
        // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
        background: {
          image: this.webp
            ? require("./assets/full_bg/easy-distillation.webp")
            : require("./assets/full_bg/easy-distillation.png"),
          // image: require("./assets/full_bg/easy-distillation.jpg"),
          position: "left 32% center",
          size: "24vw",
          correct: "margin-top: 80px;",
        },
        useFullBackground: true,
        sectionPadding: {
          top: true,
        },
      },
      {
        position: 9,
        title: "Ставьте брагу в кубе",
        hash: {
          name: "tank",
          text: "Правильный объем брожения",
        },
        subtitle: "Правильный объем брожения",
        text: "<p>Новички часто сомневаются, какой объем куба выбрать. И берут 10-20 л аппараты, думая, что это много. И для сахарной браги (которая бродит до 15% об.) маленького 20 л куба может и хватит, но для зерновой (для виски) этого будет очень мало. Вспомните, пиво (а это зерновая брага) имеет крепость всего 4-5% об., и даже с 30 л такой браги будет всего 1.5 л продукта! Именно поэтому рекомендуемый объем и для  сахарной,  и для зерновой браги будет более 30 л.</p><p>Куб DomSpirt 2 имеет базовый объём 37 л. А в будущем объём можно и увеличить! Гидрозатвор, чтобы поставить в кубе аппарата брагу, вино или сидр есть в комплекте.</p>",
        image_mobile: require("./assets/braga-tank_mobile.png"),
        background: {
          image: require("./assets/braga-tank.png"),
          position: "right 0% center",
          size: "50vw",
        },
        sectionPadding: {
          bottom: true,
        },
      },
      {
        position: 10,
        title: "Просто включите в розетку",
        hash: {
          name: "unlimited",
          text: "Просто включите в розетку.",
        },
        subtitle: "Автоматическая стабилизация",
        text: "<p>Все этапы дробной перегонки: 1) работа на себя; 2) отбор “голов”; 3) отбор “тела”; 4) дожим “хвостов” - делаются на DomSpirt 2  на одной мощности. Просто включите аппарат в розетку - и начинайте отбор!</p><p>Тарелки автоматически поддерживают постоянное количество флегмы в колонне, а значит, крепость и качество напитка. При увеличении нагрева (скачке напр.) “лишний” обратный спирт сливается через отверстия перелива; а в случае падения на каждой тарелке остаётся запас флегмы, обеспеченный высотой трубки. Таким образом, геометрия перелива делает возможным стабильную работу DomSpirt 2  на любом нагреве в диапазоне от 2.5 до 3.5 кВт  без регулировки и стабилизации - даже на дровах. </p>",
        // image_mobile: require("./assets/unlimited-volume_mobile.png"),
        // background: {
        //     image: require("./assets/unlimited-volume.png"),
        //     position: "left 30% center",
        //     size: "24vw",
        // },
        image_mobile: require("./assets/full_bg/unlimited-volume_mobile.png"),
        image_mobile_correct:
          "margin-bottom: -80px; margin-top: -150px; width: 100vw;",
        // image_mobile_default: require("./assets/clear-spirt_mobile.png"),
        background: {
          image: this.webp
            ? require("./assets/full_bg/unlimited-volume.webp")
            : require("./assets/full_bg/unlimited-volume.png"),
          // image: require("./assets/full_bg/unlimited-volume.jpg"),
          position: "left 32% center",
          size: "24vw",
          // correct: "margin-top: 80px;",
        },
        useFullBackground: true,
        sectionPadding: {
          top: true,
          bottom: true,
        },
      },
      /*{
        position: 7,
        title: 'Мощь 3"-аппарата. Безопасность 2"',
        hash: {
          name: "gold",
          text: "Золотая середина.",
        },
        subtitle: "Золотая середина.",
        text: "<p>76 мм колонна DOMSPIRT имеет рабочую мощность 3 кВт для получения скорости перегонки спирта в 2 раза выше 2” насадочных колонн, то есть на уровне колонн 3”.</p><p>Такая нагрузка на проводку и розетки позволяет безбоязненно нагревать прибор даже в “хрущёвках” с алюминиевой проводкой.</p>",
        image_mobile: require("./assets/strong-security_mobile.png"),
        background: {
          image: require("./assets/strong-security.png"),
          position: "right 20% center",
          size: "44vw",
          correct: "margin-bottom: -100px;",
        },
      },*/
      {
        position: 11,
        title: "Настойки и наливки",
        hash: {
          name: "liqueur",
          text: "Настойки и наливки",
        },
        subtitle: "Без настаивания неделями",
        text: "<p>Ситчатая конструкция тарелок (с переливом) позволяет использовать их как джин-корзину. Поставьте в колонну 1-2 тарелки и расположите на них любые натуральные ароматизаторы - сухофрукты, орешки, цедру цитрусовых, имбирь, кофе, корицу, ваниль и т.д.).</p><p>Спиртовые пары будут проходить через содержимое, насыщаться ароматом и вместо 1-2 недель Вы сможете получить ароматный напиток за 1 перегонку!</p>",
        image_mobile: require("./assets/nastoiki-nalivki_mobile.png"),
        image_mobile_correct: "margin-bottom: -130px;",
        background: {
          image: require("./assets/nastoiki-nalivki.png"),
          position: "right 5% center",
          size: "55vw",
          correct: "margin-left: 200px;",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Main/components/ReceptBlock")
          )
        ),
        hash: {
          name: "receptions",
          text: "Рецепты",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Main/components/EquipmentList")
          )
        ),
        hash: {
          name: "equipment",
          text: "Комплектация",
        },
      },
      // {
      //     component: markRaw(
      //         defineAsyncComponent(() =>
      //             import("@/pages/Main/components/MarketMetrics")
      //         )
      //     ),
      //     hash: {
      //         name: "markets",
      //         text: "Нам доверяют",
      //     },
      // },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Main/components/OrderForm")
          )
        ),
        hash: {
          name: "order",
          text: "Заказ",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Deliveries/components/DeliveryRussia")
          )
        ),
        hash: {
          name: "delivery",
          text: "Доставка",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Deliveries/components/DeliveryMap")
          )
        ),
        hash: {
          name: "delivery-map",
          text: "Карта",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Contacts/components/QuestionForm")
          )
        ),
        hash: {
          name: "questions",
          text: "Есть вопросы?",
        },
      },
      {
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Main/components/SocialBlock")
          )
        ),
        hash: {
          name: "social",
          text: "Мы в соц. сетях",
        },
      },
      /*{
        component: markRaw(
          defineAsyncComponent(() =>
            import("@/pages/Deliveries/components/DeliveryTracking")
          )
        ),
        hash: {
          name: "tracking",
          text: "Отслеживание",
        },
      },*/
      // {
      //     component: markRaw(
      //         defineAsyncComponent(() =>
      //             import("@/components/AppFooter")
      //         )
      //     ),
      //     hash: {
      //         name: "footer",
      //         text: "",
      //     },
      // },
      /*{
                title: "",
                hash: {
                    name: "",
                    text: "",
                },
                subtitle: "",
                text: "<p></p>",
                image_mobile: require("./assets/whisky-brandi_mobile.png"),
                background: {
                    image: require("./assets/whisky-brandi.png"),
                    position: "",
                    size: "",
                },
            },*/
    ];
  },
  unmounted() {
    // console.log("unmounted");
    // if (this.fullpage) this.fullpage.destroy("all");
    // this.fullpage = null;
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
#fp-nav,
.fp-slidesNav {
  ul {
    li {
      margin: 16px 7px;

      a {
        span {
          background: #ffffff;
          opacity: 0.33;
          //height: 8px;
          //width: 8px;
          //margin: -3px 0 0 -3px;
          width: 6px;
          height: 6px;
          margin: -3px 0px 0px -3px;
        }

        &.active {
          span {
            background: #ffffff;
            opacity: 0.99;
            width: 8px;
            height: 8px;
            margin: -4px 0px 0px -4px;
          }
        }
      }
    }
  }
}

.section {
  @media (min-width: 991px) {
    &#beer {
      padding: 100px 0;
      .paragraph {
        margin-top: 50px;
      }
    }
    &#aroma {
      padding-top: 50px;
      .paragraph {
        margin-top: -40px;
      }
    }
    &#delivery {
      padding-top: 0;
    }
    &#tracking {
      padding-top: 0;
    }
    &#tank {
      .paragraph {
        //margin-top: -30px;
        margin-top: 10px;
      }
    }
    &#equipment {
      padding-bottom: 0;
    }
    &#order {
      padding-top: 0;
    }
    &#gold {
      .paragraph {
        margin-top: -50px;
      }
    }
    &#liqueur {
      .paragraph {
        margin-top: 60px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    &#aroma,
    &#tank,
    &#beer {
      padding-top: 30px;
      padding-bottom: 60px;
      .image-mb {
        width: 80vw !important;
      }
    }
    &#gold {
      padding-top: 30px;
      .image-mb {
        width: 74vw !important;
      }
    }
    &#liqueur {
      padding-top: 30px;
      .image-mb {
        width: 110vw !important;
        margin-left: -60px !important;
      }
    }
    &#life {
      padding-top: 0;
    }
  }

  @media (max-width: 768px) {
    &#order {
      margin-top: 40px;
    }
  }
  @media (min-width: 768px) {
    &#tank {
      //margin-top: -50px;
      //margin-bottom: 30px;
    }
  }
}
</style>
