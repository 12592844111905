import axios from "./axios";

const post = (url, credentials, headers) =>
  axios.post(url, credentials, headers);
const get = (url) => axios.get(url);

export default {
  post,
  get,
};
