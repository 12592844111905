<template>
  <main :class="containerClass">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "AppLayout",
  props: ["containerClass"],
};
</script>

<style scoped></style>
