<template>
  <div class="app-loading">
    <div>
      <img src="../assets/loading.svg" alt="" />
      <div class="loading loading--plane" title="Loading">
        <!--        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.5 104">
          <path
            d="M97.7 41.5c16.3 0 16.3 21-.2 21H66.7l-26 38H29.2l14.1-38h-23l-7.8 10h-9L8.2 52 3.5 31.5h9l7.8 10h23.1l-14.1-38h11.4l26 38h31z"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke="#488eff"
            stroke-width="4"
          />
        </svg>-->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.003 512.003">
          <path
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke="#488eff"
            stroke-width="6"
            d="M365.397,494.03l-99.907-49.72V255.894c57.615-4.831,103.023-53.259,103.023-112.103c0-0.448-0.032-0.895-0.095-1.34
                L349.253,8.142C348.586,3.47,344.585,0,339.866,0H172.148c-4.718,0-8.72,3.47-9.387,8.142L149.28,102.62
                c-0.74,5.185,2.864,9.986,8.047,10.726c5.18,0.74,9.985-2.862,10.726-8.047l12.32-86.335H331.64l17.906,125.485
                c-0.357,51.277-42.181,92.885-93.539,92.885c-38.487,0-72.593-23.087-86.887-58.817c-1.946-4.862-7.468-7.225-12.327-5.281
                c-4.862,1.946-7.227,7.463-5.282,12.326c16.007,40.006,52.667,66.82,95.002,70.331v188.413l-99.907,49.726
                c-3.939,1.961-6.013,6.374-5.005,10.659c1.008,4.285,4.829,7.311,9.23,7.311h210.341c4.401,0,8.223-3.029,9.23-7.312
                C371.409,500.405,369.337,495.989,365.397,494.03z M191.162,493.036l64.833-32.268l64.841,32.268H191.162z"
          />

          <path
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke="#488eff"
            stroke-width="6"
            d="M253.854,186.906c-27.225,0-49.412-21.973-49.757-49.118l10.922-76.541c0.738-5.182-2.864-9.985-8.048-10.725
                c-5.171-0.732-9.985,2.862-10.726,8.047l-11.021,77.235c-0.063,0.444-0.095,0.891-0.095,1.34c0,37.896,30.83,68.725,68.725,68.725
                c5.238,0,9.482-4.245,9.482-9.482C263.336,191.151,259.092,186.906,253.854,186.906z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoading",
};
</script>

<style lang="scss" scoped>
.loading {
  width: 50px;
  height: 40px;
  margin: 40px auto;
}

.loading--plane {
  width: 70px;
  stroke-dasharray: 425.59;
  stroke-dashoffset: 425.59;
  animation: plane-loading-animation 1s linear infinite;
}

@keyframes plane-loading-animation {
  50% {
    stroke-dashoffset: 425.59;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -425.59;
  }
}

.app-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background: #202020;

  img {
    max-width: 40vw;
  }
}
</style>
