import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2389,
  sort_id: 103,
  display: true,
  subitem: 2554,
  metrikaGoal: "prokl",
  name: {
    full: "Запас прокладок",
    short: "Запас прокладок",
  },
  component: markRaw(
    defineAsyncComponent(() =>
      import("@/store/modules/items/zapas-prokladok/html")
    )
  ),
  price: 990,
  layers: {
    "*": [
      {
        layer: 56,
        name: "prokl_zoom",
        url: require("@/pages/Main/assets/order5/prokl.png"),
      },
      {
        layer: 56,
        name: "prokl",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/prokl.png"),
      },
    ],
  },
  count: 1,
  active: false,
};
