import webp from "@/functions/webp";

export default {
  display: true,
  hidePlus: true,
  hide_mobile: true,
  id: 2554,
  sort_id: 100,
  name: {
    full: "Колонна 6 медных тарелок",
    short: "Колонна 6 медных тарелок",
  },
  html: "",
  price: 19990,
  layers: {
    0: [
      {
        layer: 51,
        name: "potstill_zoom",
        url: require("@/pages/Main/assets/order5/potstill.png") + webp(),
      },
      {
        layer: 56,
        name: "potstill",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/potstill.png"),
      },
      // {
      //   layer: 61,
      //   name: "deflegmator_carga_12",
      //   url:
      //     require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
      //     webp(),
      // },
      // {
      //   layer: 61,
      //   name: "carga_med_6",
      //   url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
      // },
      // {
      //   layer: 62,
      //   name: "carga_nerj_12",
      //   hide: ["carga_nerj_6_wt", "carga_med_6_wt"],
      //   url: require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
      // },
      {
        layer: 61,
        name: "carga_med_6_zoom",
        hide: [],
        url: require("@/pages/Main/assets/order5/carga_med_6.png") + webp(),
      },
      {
        layer: 62,
        name: "deflegmator_6_zoom",
        hide: [],
        url: require("@/pages/Main/assets/order5/deflegmator_6.png") + webp(),
      },
    ],
    1: [
      {
        layer: 51,
        name: "potstill_zoom",
        url: require("@/pages/Main/assets/order5/potstill.png") + webp(),
      },
      {
        layer: 56,
        name: "potstill",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/potstill.png"),
      },
      // {
      //   layer: 61,
      //   name: "deflegmator_carga_12_size",
      //   url:
      //     require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
      //     webp(),
      // },
      // {
      //   layer: 61,
      //   name: "carga_med_6_size",
      //   url:
      //     require("@/pages/Main/assets/order4/carga_med_6_size.png") + webp(),
      // },
      // {
      //   layer: 62,
      //   name: "carga_nerj_12_size",
      //   hide: ["carga_nerj_6_wt_size", "carga_med_6_wt_size"],
      //   url:
      //     require("@/pages/Main/assets/order4/carga_nerj_12_size.png") + webp(),
      // },
      {
        layer: 61,
        name: "carga_med_6_size_zoom",
        hide: [],
        url:
          require("@/pages/Main/assets/order5/carga_med_6_size.png") + webp(),
      },
      {
        layer: 62,
        name: "deflegmator_6_size_zoom",
        hide: [],
        url:
          require("@/pages/Main/assets/order5/deflegmator_6_size.png") + webp(),
      },
    ],
  },
  // variant_id: 2555,
  // variants: [
  //   {
  //     id: 2552,
  //     name: {
  //       full: "нерж.",
  //       short: "нерж.",
  //     },
  //     style: {
  //       "--color-from": "#d9d9d9",
  //       "--color-to": "#e0e0e0",
  //       "--color-selected": "#000",
  //       "--bg": "linear-gradient(to right, #a2a2a2, #8a8a8a)",
  //     },
  //     price: 23990,
  //     layers: {
  //       0: [
  //         {
  //           layer: 51,
  //           name: "potstill",
  //           url: require("@/pages/Main/assets/order4/potstill.png") + webp(),
  //         },
  //         // {
  //         //   layer: 63,
  //         //   name: "deflegmator_carga_6",
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/deflegmator_carga_6.png") +
  //         //     webp(),
  //         // },
  //         // {
  //         //   layer: 62,
  //         //   name: "carga_nerj_6_wt",
  //         //   hide: ["carga_med_6"],
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/carga_nerj_6_wt.png") +
  //         //     webp(),
  //         // },
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
  //         },
  //         // {
  //         //   layer: 61,
  //         //   name: "carga_med_6",
  //         //   url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
  //         // },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12",
  //           hide: ["carga_nerj_6_wt", "carga_med_6_wt"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
  //         },
  //       ],
  //       1: [
  //         {
  //           layer: 51,
  //           name: "potstill",
  //           url: require("@/pages/Main/assets/order4/potstill.png") + webp(),
  //         },
  //         // {
  //         //   layer: 63,
  //         //   name: "deflegmator_carga_6_size",
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/deflegmator_carga_6_size.png") +
  //         //     webp(),
  //         // },
  //         // {
  //         //   layer: 62,
  //         //   name: "carga_nerj_6_wt_size",
  //         //   hide: ["carga_med_6_size"],
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/carga_nerj_6_wt_size.png") +
  //         //     webp(),
  //         // },
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12_size",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6_size.png") +
  //             webp(),
  //         },
  //         // {
  //         //   layer: 61,
  //         //   name: "carga_med_6_size",
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/carga_med_6_size.png") +
  //         //     webp(),
  //         // },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12_size",
  //           hide: ["carga_nerj_6_wt_size", "carga_med_6_wt_size"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12_size.png") +
  //             webp(),
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 2555,
  //     name: {
  //       full: "медь",
  //       short: "медь",
  //     },
  //     style: {
  //       "--color-from": "#ffa353",
  //       "--color-to": "#d95e00",
  //       "--color-selected": "#2c2c2c",
  //       "--bg": "linear-gradient(to right, #ffb753, #d95e00)",
  //     },
  //     price: 27980,
  //     layers: {
  //       0: [
  //         {
  //           layer: 51,
  //           name: "potstill",
  //           url: require("@/pages/Main/assets/order4/potstill.png") + webp(),
  //         },
  //         // {
  //         //   layer: 63,
  //         //   name: "deflegmator_carga_6",
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/deflegmator_carga_6.png") +
  //         //     webp(),
  //         // },
  //         // {
  //         //   layer: 62,
  //         //   name: "carga_med_6_wt",
  //         //   hide: ["carga_nerj_6"],
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/carga_med_6_wt.png") + webp(),
  //         // },
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
  //             webp(),
  //         },
  //         // {
  //         //     layer: 61,
  //         //     name: "carga_nerj_6",
  //         //     url: require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
  //         // },
  //         {
  //           layer: 61,
  //           name: "carga_med_6",
  //           url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12",
  //           hide: ["carga_nerj_6_wt", "carga_med_6_wt"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
  //         },
  //       ],
  //       1: [
  //         {
  //           layer: 51,
  //           name: "potstill",
  //           url: require("@/pages/Main/assets/order4/potstill.png") + webp(),
  //         },
  //         // {
  //         //   layer: 63,
  //         //   name: "deflegmator_carga_6_size",
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/deflegmator_carga_6_size.png") +
  //         //     webp(),
  //         // },
  //         // {
  //         //   layer: 62,
  //         //   name: "carga_med_6_wt_size",
  //         //   hide: ["carga_nerj_6_size"],
  //         //   url:
  //         //     require("@/pages/Main/assets/order4/carga_med_6_wt_size.png") +
  //         //     webp(),
  //         // },
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12_size",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_med_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_med_6_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12_size",
  //           hide: ["carga_nerj_6_wt_size", "carga_med_6_wt_size"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12_size.png") +
  //             webp(),
  //         },
  //       ],
  //     },
  //   },
  // ],
  // layers: {
  //   0: [
  //     {
  //       layer: 52,
  //       name: "up",
  //       url: require("@/pages/Main/assets/order/up.png") + webp(),
  //     },
  //     {
  //       layer: 51,
  //       name: "tsarga_nerj",
  //       url: require("@/pages/Main/assets/order/tsarga_nerj.png") + webp(),
  //     },
  //     {
  //       layer: 62,
  //       name: "up_uo",
  //       hide: ["up"],
  //       url: require("@/pages/Main/assets/order/up_uo.png") + webp(),
  //     },
  //   ],
  //   1: [
  //     {
  //       layer: 53,
  //       name: "up_size",
  //       url: require("@/pages/Main/assets/order/up_size.png") + webp(),
  //     },
  //     {
  //       layer: 52,
  //       name: "tsarga_nerj_size",
  //       url: require("@/pages/Main/assets/order/tsarga_nerj_size.png") + webp(),
  //     },
  //     {
  //       layer: 62,
  //       name: "up_uo_size",
  //       hide: ["up_size"],
  //       url: require("@/pages/Main/assets/order/up_uo_size.png") + webp(),
  //     },
  //   ],
  // },
  count: 1,
  active: true,
};
