<template>
  <div class="container">
    <div class="row">
      <!--      <div class="col-4 col-xl align-self-center left-section text-center">-->
      <!--        <div class="action-title">⚡️Акция</div>-->
      <!--        <div class="action-subtitle">при заказе до {{ dateUp1 }}</div>-->
      <!--        <div class="action">-->
      <!--          <div class="row align-items-center mx-0">-->
      <!--            <div class="col-12 px-0">-->
      <!--              <img-->
      <!--                v-lazy="{-->
      <!--                  src: require('./assets/uo_sale_max.png'),-->
      <!--                }"-->
      <!--              />-->
      <!--            </div>-->
      <!--            <div class="col px-0">-->
      <!--              <a-->
      <!--                href="#"-->
      <!--                @click.prevent="showItem(saleItem)"-->
      <!--                class="d-block text-primary text-decoration-underline"-->
      <!--              >-->
      <!--                <span>Узел отбора</span>-->
      <!--              </a>-->
      <!--              в подарок!-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        &lt;!&ndash;                <div class="history">-->
      <!--                    <div class="history-title">С 25.07 добавлены:</div>-->
      <!--                    <div-->
      <!--                        class="row align-items-center history-item mx-0"-->
      <!--                        v-for="(item, index) in history"-->
      <!--                        :key="'history_' + index"-->
      <!--                    >-->
      <!--                        <div class="col-auto px-0">-->
      <!--                            <img v-lazy="{ src: item.image }" />-->
      <!--                        </div>-->
      <!--                        <div class="col px-0">{{ item.title }}</div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <a-->
      <!--                    href="#"-->
      <!--                    class="who-is-order"-->
      <!--                    @click.prevent="$refs.modalOrder.open()"-->
      <!--                    >А кто заказал до 25.07?</a-->
      <!--                >&ndash;&gt;-->
      <!--      </div>-->
      <div class="col d-flex justify-content-center">
        <!--                <img
                    v-lazy="{ src: require('./assets/start-block.png') }"
                    class="start-image"
                />-->
        <img
          :src="webp(require('./assets/start-block.png'))"
          class="start-image"
          alt=""
        />
      </div>
      <div class="col-auto align-self-center right-section">
        <div class="start-title">Самогонный аппарат</div>
        <!--        <img src="./assets/logo.svg" alt="" />-->
        <div class="logo">DOMSPIRT <span>2</span></div>
        <div class="list">
          <div
            class="list-item"
            v-for="(item, index) in list"
            :key="'item_' + index"
          >
            <div class="row mx-0">
              <div class="col-auto px-0">
                <div class="list-item-number">
                  {{ index + 1 }}
                </div>
              </div>
              <div class="col px-0">
                <div class="list-item-title">
                  {{ item.title }}
                </div>
                <div class="list-item-text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row delivery-info mx-0">
          <div class="col-auto px-0">
            <img v-lazy="{ src: require('./assets/calend.svg') }" />
          </div>
          <div class="col px-0">
            <div>{{ dateNow }} доставка бесплатно!</div>
            <div>Оплата после проверки при вручении.</div>
          </div>
        </div>
        <div class="video">
          <a
            href="javascript:void(0)"
            @click="openVideo(BASE_URL + '/media/video2.mp4')"
          >
            <img src="./assets/play.svg" alt="" />
            <span>Видео</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="promo-box" v-if="promo && promoActive">
    <div class="container py-3">
      <div class="row align-items-center">
        <div class="col">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="promo-text">Активирован промокод:</div>
            </div>
            <div class="col-auto">
              <div class="promo-code">{{ promo.code }}</div>
            </div>
            <div class="col-auto">
              <div class="promo-info">Запас прокладок в подарок!</div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <a href="#order" class="btn btn-primary px-4 text-decoration-none"
            >Оформить заказ</a
          >
        </div>
      </div>
    </div>
  </div>

  <!--    <Modal ref="modalOrder">
        <h3>Уважаемые клиенты!</h3>
        <p>
            С 25.07 прибор DomSpirt комплектуется несколькими небольшими
            дополнениями:
        </p>
        <div class="after-order">
            <h5>1. Модуль контроля температуры;</h5>
            <p>
                Позволяет отслеживать температуру в колонне на любом уровне, а в
                ниппель можно установить как щуп термометра 4 мм, так и датчик 6
                мм.
            </p>

            <p>
                Надо ли следить за температурой в колонне? В прозрачной колонне
                «хвосты» очень хорошо видны, как запотевание и конденсат.
                Обычно, когда конденсат поднимается до 13-14 стекла, термометр
                на верху колонны фиксирует «скачок» на 0.2-0.4°С и показывает,
                что перегонку пора прекращать. Поэтому в «ручном» режиме
                управления контроль температуры в царге не нужен. Однако сейчас
                мы разрабатываем классическую автоматику, которая может уловить
                окончание отбора лишь по «скачку» температуры и поэтому
                необходимость цифрового контроля возникла.
            </p>
            <h5>2. Противовзрывной клапан на куб;</h5>
            <p>
                Нужен ли противовзрывной клапан? Если на кубе установлена
                тарельчатая колонна, как в DomSpirt, - нет, не нужен.
                Тарельчатые колонны - самые безопасные, т.к. гидравлическое
                сопротивление ситчатых тарелок минимальное и давление в кубе
                просто не повышается. В отличие от насадочных, где насадку можно
                перетрамбовать, в результате чего давление повышается настолько,
                насколько уплотнена насадка. Однако в связи с большим
                количеством запросов мы начали продавать перегонные кубы
                отдельно и уже не можем знать, какая колонна будет установлена
                на куб. Поэтому с 25.07 на кубах DomSpirt появился
                предохранительный клапан, а ранее их не было.
            </p>
            <h5>
                3. Комплект самозажимных хомутов (4 шт на штуцера, 3 шт на
                тройник и 1 шт на переходник на кран);
            </h5>
            <p>
                Жарким летом, в связи с тем, что у многих из холодного крана
                бежит тёплая вода, дефлегматору нужно больше жидкости.
                Увеличение расхода воды заставляет некоторых пользователей
                переживать за трубки на штуцерах, однако мы, ещё при выпуске
                Люкссталь 8, проводили тест доказывающий, что силиконовая трубка
                со штуцера слететь не может (видео на ютуб-канале «Luxstahl» от
                27.05.2021 - Надежность штуцерного соединения). Но «для
                успокоения души» некоторых пользователей всё же принято решение
                добавить в комплект пружинные самозажимные хомуты.
            </p>
            <p>
                Исходя из вышесказанного, все 3 нововведения не обязательны, но
                их может захотеться получить, если Вы уже заказывали DomSpirt
                ранее.
            </p>
            <p>
                Доставка по РФ комплекта (блок температурного контроля + клапан
                на куб + комплект хомутов) стоит всего 990 р., сами товары
                комплекта, если Вы покупали у нас аппарат до 25.07, оплачивать
                не нужно. Фактически, заменив модуль контроля вместо одного
                стекла, у Вас появляется запасное стекло! (Стоит тоже 990 р на
                сайте).
            </p>
            <p>
                Итак, если Вы уже приобрели на нашем сайте аппарат DomSpirt и
                хотели бы получить комплект из всех трёх дополнений (модуль
                контроля температуры, клапан, набор хомутов), напишите нам на
                электронную почту info@domspirt.ru, указав номер Вашего заказа
                аппарата DomSpirt или номер телефона. В ближайшее время на нашем
                канале выйдет видео, как можно установить клапан на куб
                самостоятельно.
            </p>
        </div>
    </Modal>-->
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import { EDIT_MODAL } from "@/store/modules/order";
import { webp } from "@/plugins/webp";
// import Modal from "@/ui/Modal";

const BASE_URL =
  process.env.NODE_ENV === "production" ? process.env.BASE_URL : "";

export default {
  name: "StartBlockDesktop",
  components: {},
  data: () => ({
    BASE_URL,
    list: [
      {
        title: "18 ступеней перегонки",
        text: "Прозрачная гарантия чистоты и крепости.",
      },
      {
        title: "№1 для ароматных дистиллятов",
        text: "Виски, бренди, коньяк, кальвадос",
      },
      {
        title: "Виски и пиво",
        text: "Правильный куб для работы с солодом.",
      },
      {
        title: "3 дюйма",
        text: 'В 2 раза мощнее 2" аналогов.',
      },
      {
        title: "14 л/час",
        text: "Мощный димрот и 3-трубный доохладитель",
      },
    ],
    history: [
      {
        image: require("./assets/temp_history.png"),
        title: "Контроль температуры в царге",
      },
      {
        image: require("./assets/klap_history.png"),
        title: "Противовзрывной клапан на куб",
      },
      {
        image: require("./assets/hom_history.png"),
        title: "Cамозажимные хомуты на штуцера",
      },
    ],
  }),
  computed: {
    dateNow() {
      return this.$store.getters.date;
    },
    dateUp1() {
      return this.$store.getters.dateUp1;
    },
    // baseItem() {
    //   return this.$store.getters.items.find((v) => v.id === 2250);
    // },
    items() {
      return this.$store.getters.items;
    },
    // saleItem() {
    //   return this.items.find((v) => parseInt(v.id) === parseInt(2333));
    // },
    dateSale() {
      let startDay = 182;

      let now = new Date();
      let start = new Date(now.getFullYear(), 0, 0);
      let diff = now - start;
      let oneDay = 1000 * 60 * 60 * 24;
      let dayOfYear = Math.floor(diff / oneDay);

      const date = new Date(
        new Date().getFullYear(),
        0,
        startDay + parseInt((dayOfYear - startDay) / 6) * 6 + 5
      );

      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      return day + "." + month;
    },
    promo() {
      return this.$store.getters.promo;
    },
    kits() {
      return this.$store.getters.kits;
    },
    cartIds() {
      let cartForSent = this.$store.getters.cart.map((v) => {
        let res = null;
        let item = this.$store.getters.items.find((find) => find.id == v.id);
        if (item.variant_id) {
          res = item.variants.find((variant) => variant.id == item.variant_id);
        } else {
          res = item;
        }
        return { ...v, id: res.id };
      });

      return cartForSent.map((v) => {
        return v.id;
      });
    },
    promoActive() {
      let res = [];
      if (Object.keys(this.promo.required_items).length > 0) {
        let usedKit = false;
        let cartForSent = this.$store.getters.cart;
        this.kits.forEach((value) => {
          if (!usedKit) {
            let activeKit = value.items.every((r) => this.cartIds.includes(r));
            if (activeKit) {
              usedKit = true;

              cartForSent = cartForSent.filter(
                (v) => !value.items.includes(v.id)
              );

              cartForSent.push({
                id: value.id,
                count: 1,
              });
            }
          }
        });
        if (this.promo.any) {
          res = this.promo.required_items.some((r) =>
            cartForSent.map((v) => v.id).includes(r)
          );
        } else {
          res = this.promo.required_items.every((r) =>
            cartForSent.map((v) => v.id).includes(r)
          );
        }
      } else {
        res = false;
      }
      return res;
    },
  },
  methods: {
    webp,
    openVideo(src) {
      function gcd(a, b) {
        return b == 0 ? a : gcd(b, a % b);
      }

      this.getVideoDimensionsOf(src).then((video) => {
        let w = video.width;
        let h = video.height;
        let r = gcd(w, h);

        Fancybox.show(
          [
            {
              src: src,
              ratio: w / r / (h / r),
              width: video.width,
              height: video.height,
            },
          ],
          {
            preload: 0,
          }
        );
      });
    },
    getVideoDimensionsOf(url) {
      return new Promise((resolve) => {
        // create the video element
        const video = document.createElement("video");

        // place a listener on it
        video.addEventListener(
          "loadedmetadata",
          () => {
            // retrieve dimensions
            const height = video.videoHeight;
            const width = video.videoWidth;

            // send back result
            resolve({ height, width });
          },
          false
        );

        // start download meta-datas
        video.src = url;
      });
    },
    // parsePrice: function (str) {
    //     return parseFloat(str)
    //         .toString()
    //         .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1’");
    // },
    showItem(item) {
      this.$store.commit(EDIT_MODAL, { ...item, hideOrderButtons: true });
    },
  },
};
</script>

<style scoped lang="scss">
.start-image {
  width: 620px;
  height: 898px;
  margin: 20px -250px -20px -230px;
  z-index: 0;

  @media (max-width: 1199px) {
    margin: 60px -280px 0px -360px;
    width: 536px;
    height: 688px;
  }
}

.left-section,
.right-section {
  z-index: 1;
  margin-top: -80px;
}

.action-title {
  color: #ff9100;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.action-subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.action {
  margin-top: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  img {
    //margin-right: 14px;
    margin-bottom: 23px;
  }

  a {
    color: #0086fc;
    text-decoration-line: underline;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    span {
      font-size: 26px;
    }
  }
}

.history {
  margin-top: 52px;
  margin-bottom: 24px;

  img {
    margin-right: 14px;
  }
  .history-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .history-item {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    margin-top: 20px;
  }
}

.who-is-order {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #0086fc;
  text-decoration-line: underline;
  padding-bottom: 52px;
}

.list {
  margin-top: 52px;
  .list-item {
    margin-bottom: 14px;
    .list-item-number {
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 100px;
      width: 22px;
      height: 22px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      opacity: 0.5;
      padding-top: 1px;
      margin-top: 5px;
    }
    .list-item-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
    .list-item-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      opacity: 0.75;
    }
  }
}
.delivery-info {
  margin-top: 52px;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;

  background-image: linear-gradient(to left, #ffa353, #d95e00);
  color: transparent;
  /* purgecss start ignore */
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: 0 0 transparent;
  /* purgecss end ignore */

  img {
    margin-right: 12px;
  }
}

.video {
  a {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 36px;
    color: #2e63fd;

    img {
      background: #2e63fd;
      width: 55px;
      height: 55px;
      padding: 15px 12px 15px 15px;
      border-radius: 100px;
    }
    span {
      color: #2e63fd;
      font-weight: 500;
      font-size: 23px;
      margin-left: 15px;
      border-bottom: 1px solid rgba(46, 106, 243, 0.25);
      line-height: 1.3;
    }

    &:hover {
      text-decoration: none;
      span {
        border-bottom: 1px solid rgba(46, 106, 243, 0.9);
      }
    }
    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 7px;
    }
  }
}
.after-order {
  h5,
  p {
    font-size: 14px;
    line-height: 22px;
    color: #e0e0e0;
  }
}
.start-title {
  margin-bottom: 14px;
}
.logo {
  font-weight: 400;
  font-size: 85px;
  margin: -25px -6px;
  line-height: 80px;
  font-family: "Righteous", cursive;
  background-image: linear-gradient(180deg, #e1e2e3 0%, #b0b3bc 100%);
  color: transparent;
  /* purgecss start ignore */
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: 0 0 transparent;
  /* purgecss end ignore */
  span {
    font-size: 110px;
  }
}

.promo-box {
  background: #363636;
  .promo-text {
    font-size: 16px;
  }
  .promo-code {
    font-size: 17px;
    text-transform: capitalize;
    background-image: linear-gradient(to left, #ffa353, #d95e00);
    //background: #0d6efd;
    padding: 3px 25px;
    box-shadow: inset 0px 4px 8px -5px black;
    border-radius: 5px;
    font-weight: 600;
    text-shadow: 0px 3px 4px black;
  }
  .promo-info {
    font-size: 20px;
  }
}
</style>
