import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2265,
  sort_id: 101,
  display: true,
  subitem: 2554,
  metrikaGoal: "carga12",
  name: {
    full: "Царга 12 тарелок",
    short: "Царга +12 тар.",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/dop-carga12/html"))
  ),
  price: 9990,
  layers: {
    0: [
      {
        layer: 61,
        name: "deflegmator_carga_12",
        hide: [
          "deflegmator_6_zoom",
          "tank_zoom",
          "prokl_zoom",
          "size_zoom",
          "dno_ten_zoom",
          "ten_zoom",
          "dno_zoom",
          "regul_zoom",
          "automatic_zoom",
          "glass_zoom",
          "potstill_zoom",
        ], // privet, kostyl!
        url:
          require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
          webp(),
      },
      {
        layer: 61,
        name: "carga_med_6",
        hide: ["potstill_zoom"],
        url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
      },
      {
        layer: 61,
        name: "carga_med_6",
        hide: ["carga_med_6_zoom"],
        url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
      },
      {
        layer: 62,
        name: "carga_nerj_12",
        url: require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
      },
    ],
    1: [
      {
        layer: 61,
        name: "deflegmator_carga_12_size",
        hide: [
          "carga_med_6_zoom",
          "deflegmator_6_zoom",
          "carga_med_6_size_zoom",
          "deflegmator_6_size_zoom",
          "tank_zoom",
          "prokl_zoom",
          "size_zoom",
          "dno_ten_zoom",
          "ten_zoom",
          "dno_zoom",
          "regul_zoom",
          "automatic_zoom",
          "glass_zoom",
          "potstill_zoom",
        ], // privet, kostyl!
        url:
          require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
          webp(),
      },
      {
        layer: 61,
        name: "carga_med_6_size",
        // hide: ["carga_med_6_zoom"],
        url:
          require("@/pages/Main/assets/order4/carga_med_6_size.png") + webp(),
      },
      {
        layer: 62,
        name: "carga_nerj_12_size",
        url:
          require("@/pages/Main/assets/order4/carga_nerj_12_size.png") + webp(),
      },
      // {
      //   layer: 61,
      //   name: "deflegmator_carga_12_size",
      //   hide: ["carga_med_6_size_zoom", "deflegmator_6_size_zoom"],
      //   url:
      //     require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
      //     webp(),
      // },
    ],
    // "*": [
    //   {
    //     layer: 61,
    //     name: "dop_carga",
    //     url: require("@/pages/Main/assets/order4/dop-carga.png") + webp(),
    //   },
    // ],
    // 0: [
    //   {
    //     layer: 61,
    //     name: "deflegmator_carga_12",
    //     url:
    //       require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
    //       webp(),
    //   },
    //   {
    //     layer: 61,
    //     name: "carga_nerj_6",
    //     url: require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
    //   },
    //   {
    //     layer: 61,
    //     name: "carga_med_6",
    //     url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
    //   },
    //   {
    //     layer: 62,
    //     name: "carga_nerj_12",
    //     hide: ["deflegmator_carga_6", "carga_nerj_6_wt", "carga_med_6_wt"],
    //     url: require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
    //   },
    // ],
    // 1: [
    //   {
    //     layer: 61,
    //     name: "deflegmator_carga_12_size",
    //     url:
    //       require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
    //       webp(),
    //   },
    //   {
    //     layer: 61,
    //     name: "carga_nerj_6_size",
    //     url:
    //       require("@/pages/Main/assets/order4/carga_nerj_6_size.png") + webp(),
    //   },
    //   {
    //     layer: 61,
    //     name: "carga_med_6_size",
    //     url:
    //       require("@/pages/Main/assets/order4/carga_med_6_size.png") + webp(),
    //   },
    //   {
    //     layer: 62,
    //     name: "carga_nerj_12_size",
    //     hide: [
    //       "deflegmator_carga_6_size",
    //       "carga_nerj_6_wt_size",
    //       "carga_med_6_wt_size",
    //     ],
    //     url:
    //       require("@/pages/Main/assets/order4/carga_nerj_12_size.png") + webp(),
    //   },
    // ],
  },
  // variant_id: 2265,
  // variants: [
  //   {
  //     id: 2265,
  //     name: {
  //       full: "1 шт.",
  //       short: "1 шт.",
  //     },
  //     style: {
  //       "--color-from": "#d9d9d9",
  //       "--color-to": "#e0e0e0",
  //       "--color-selected": "#000",
  //       "--bg": "linear-gradient(to right, #a2a2a2, #8a8a8a)",
  //     },
  //     price: 7990,
  //     layers: {
  //       0: [
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_med_6",
  //           url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12",
  //           hide: ["deflegmator_carga_6", "carga_nerj_6_wt", "carga_med_6_wt"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
  //         },
  //       ],
  //       1: [
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12_size",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_med_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_med_6_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12_size",
  //           hide: [
  //             "deflegmator_carga_6_size",
  //             "carga_nerj_6_wt_size",
  //             "carga_med_6_wt_size",
  //           ],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12_size.png") +
  //             webp(),
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 7777,
  //     name: {
  //       full: "2 шт.",
  //       short: "2 шт.",
  //     },
  //     style: {
  //       "--color-from": "#d9d9d9",
  //       "--color-to": "#e0e0e0",
  //       "--color-selected": "#000",
  //       "--bg": "linear-gradient(to right, #a2a2a2, #8a8a8a)",
  //     },
  //     price: 7990 * 2,
  //     layers: {
  //       "*": [
  //         {
  //           layer: 51,
  //           name: "dop_carga",
  //           url: require("@/pages/Main/assets/order4/dop-carga.png") + webp(),
  //         },
  //       ],
  //       0: [
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_med_6",
  //           url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12",
  //           hide: ["deflegmator_carga_6", "carga_nerj_6_wt", "carga_med_6_wt"],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
  //         },
  //       ],
  //       1: [
  //         {
  //           layer: 61,
  //           name: "deflegmator_carga_12_size",
  //           url:
  //             require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_nerj_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_6_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 61,
  //           name: "carga_med_6_size",
  //           url:
  //             require("@/pages/Main/assets/order4/carga_med_6_size.png") +
  //             webp(),
  //         },
  //         {
  //           layer: 62,
  //           name: "carga_nerj_12_size",
  //           hide: [
  //             "deflegmator_carga_6_size",
  //             "carga_nerj_6_wt_size",
  //             "carga_med_6_wt_size",
  //           ],
  //           url:
  //             require("@/pages/Main/assets/order4/carga_nerj_12_size.png") +
  //             webp(),
  //         },
  //       ],
  //     },
  //   },
  // ],
  /*layers: {
    0: [
      {
        layer: 61,
        name: "deflegmator_carga_12",
        url:
          require("@/pages/Main/assets/order4/deflegmator_carga_12.png") +
          webp(),
      },
      {
        layer: 61,
        name: "carga_nerj_6",
        url: require("@/pages/Main/assets/order4/carga_nerj_6.png") + webp(),
      },
      {
        layer: 61,
        name: "carga_med_6",
        url: require("@/pages/Main/assets/order4/carga_med_6.png") + webp(),
      },
      {
        layer: 62,
        name: "carga_nerj_12",
        hide: ["deflegmator_carga_6", "carga_nerj_6_wt", "carga_med_6_wt"],
        url: require("@/pages/Main/assets/order4/carga_nerj_12.png") + webp(),
      },
    ],
    1: [
      {
        layer: 61,
        name: "deflegmator_carga_12_size",
        url:
          require("@/pages/Main/assets/order4/deflegmator_carga_12_size.png") +
          webp(),
      },
      {
        layer: 61,
        name: "carga_nerj_6_size",
        url:
          require("@/pages/Main/assets/order4/carga_nerj_6_size.png") + webp(),
      },
      {
        layer: 61,
        name: "carga_med_6_size",
        url:
          require("@/pages/Main/assets/order4/carga_med_6_size.png") + webp(),
      },
      {
        layer: 62,
        name: "carga_nerj_12_size",
        hide: [
          "deflegmator_carga_6_size",
          "carga_nerj_6_wt_size",
          "carga_med_6_wt_size",
        ],
        url:
          require("@/pages/Main/assets/order4/carga_nerj_12_size.png") + webp(),
      },
    ],
  },*/
  count: 1,
  active: false,
};
