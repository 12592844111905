import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueLazyLoad from "@jambonn/vue-lazyload";
import Vue3TouchEvents from "vue3-touch-events";
const VueScrollTo = require("vue-scrollto");
import VueYandexMetrika from "vue3-yandex-metrika";
// import { initYandexMetrika } from "yandex-metrika-vue3";
import Maska from "maska";

require("bootstrap");
require("bootstrap/dist/css/bootstrap.min.css");
require("./sass/fonts.scss");
require("./sass/app.scss");

require("swiped-events");

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const app = createApp(App);

// app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("model-");

// const inBrowser = typeof window !== "undefined" && window !== null;
//
// function supportWebp() {
//     if (!inBrowser) return false;
//
//     let support = true;
//
//     try {
//         const elem = document.createElement("canvas");
//
//         if (elem.getContext && elem.getContext("2d")) {
//             support =
//                 elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
//         }
//     } catch (err) {
//         support = false;
//     }
//
//     return support;
// }

app
  .use(VueLazyLoad, {
    preLoad: 1.3,
    // lifecycle: {
    //     loading: (el) => {
    //         console.log("loading", el);
    //     },
    //     error: (el) => {
    //         console.log("error", el);
    //     },
    //     loaded: (el) => {
    //         console.log("loaded", el);
    //     },
    // },
    // adapter: {
    //     loaded({ el }) {
    //         // do something here
    //         // example for call LoadedHandler
    //         console.log(el);
    //     },
    //     loading() {
    //         console.log("loading");
    //     },
    //     error() {
    //         console.log("error");
    //     },
    // },
    filter: {
      // progressive(listener, options) {
      //     const isCDN = /qiniudn.com/;
      //     if (isCDN.test(listener.src)) {
      //         listener.el.setAttribute("lazy-progressive", "true");
      //         listener.loading = listener.src + "?imageView2/1/w/10/h/10";
      //     }
      // },
      webp(listener, options) {
        // console.log(listener, options);
        if (!options.supportWebp) return;
        const isCDN = /\.(jpe?g|png)$/;
        if (isCDN.test(listener.src)) {
          listener.src += ".webp";
        }
      },
    },
  })
  .use(Vue3TouchEvents)
  .use(VueScrollTo, {
    lazy: false,
  })
  .use(Maska)
  .directive("click-outside", clickOutside);

if (!window.isRobot) {
  app.use(VueYandexMetrika, {
    id: 88793382,
    router: router,
    env: process.env.NODE_ENV,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      userParams: {
        version: "v4",
      },
    },
    // other options
  });

  // app.use(initYandexMetrika, {
  //   id: 88793382,
  //   router: router,
  //   env: process.env.NODE_ENV,
  //   options: {
  //     clickmap: true,
  //     trackLinks: true,
  //     accurateTrackBounce: true,
  //     webvisor: true,
  //     triggerEvent: true,
  //     userParams: {
  //       version: "v4",
  //     },
  //   },
  // });
}

app.use(store).use(router).mount("#app");
