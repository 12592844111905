<template>
  <Transition name="bounce-modal">
    <div class="item-modal-container" v-if="modal" @click.self="closeItem">
      <div class="item-modal">
        <button class="item-modal-close" @click="closeItem">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.22566 4.81096C5.83514 4.42044 5.20197 4.42044 4.81145 4.81096C4.42092 5.20148 4.42092 5.83465 4.81145 6.22517L10.5862 11.9999L4.81151 17.7746C4.42098 18.1651 4.42098 18.7983 4.81151 19.1888C5.20203 19.5793 5.8352 19.5793 6.22572 19.1888L12.0004 13.4141L17.7751 19.1888C18.1656 19.5793 18.7988 19.5793 19.1893 19.1888C19.5798 18.7983 19.5798 18.1651 19.1893 17.7746L13.4146 11.9999L19.1893 6.22517C19.5799 5.83465 19.5799 5.20148 19.1893 4.81096C18.7988 4.42044 18.1657 4.42044 17.7751 4.81096L12.0004 10.5857L6.22566 4.81096Z"
              fill="current"
            />
          </svg>
        </button>
        <div v-if="modal.component" class="item-modal-body">
          <component :is="modal.component" :item="modal"></component>
        </div>
        <div v-else v-html="modal.html" class="item-modal-body"></div>
        <div
          class="row mx-0 item-modal-footer align-items-start justify-content-end"
        >
          <div class="col-12 px-0" v-if="modal.saleInfo">
            <div
              class="sale-info"
              :class="{ 'pb-25px': !modal.hideOrderButtons }"
            >
              {{ modal.saleInfo }}
            </div>
          </div>
          <div
            class="col-12 px-0 col-md d-flex align-items-end justify-content-center justify-content-md-start"
            v-if="!modal.hideOrderButtons && (!modal.gift || orderOne)"
          >
            <div class="row">
              <div class="col-12">
                <span class="price-text">Цена:</span>
                <span class="price-value">
                  {{ parsePrice(getVariant(modal.id).price) }} ₽
                </span>
              </div>
              <div class="col-12" v-if="orderOne">
                <div class="order-help">
                  <div class="row mx-0">
                    <div class="col-auto px-0">
                      <img
                        v-lazy="{
                          src: require('../assets/alert.svg'),
                        }"
                        alt=""
                      />
                    </div>
                    <div class="col ps-0" v-if="!success">
                      Чтобы оформить заказ, введите Ваш телефон. Мы перезвоним
                      или ответим в WhatsApp, Viber или Telegram для оформления
                      доставки на удобный пункт выдачи в Вашем городе.
                    </div>
                    <div class="col ps-0" v-if="success">
                      Ожидайте звонка в течение 30 мин. Мы работаем с 9 до 21.00
                      по Москве
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" v-if="!modal.hideOrderButtons">
            <div class="order-one" v-if="orderOne">
              <input
                id="modal-phone"
                type="text"
                v-maska="mask"
                v-model="form.phone"
                :disabled="ordering || success"
                placeholder="Введите телефон"
                :class="{ error: errors.includes('phone') }"
                @input="changePhone"
              />
            </div>
            <div class="row">
              <div class="col-12 col-md" v-if="!orderOne && !isGift">
                <button
                  v-if="modal.active"
                  class="toggle-order-item"
                  :class="{ remove: modal.active }"
                  @click="toggleItem(modal)"
                >
                  <img src="../assets/minus.svg" alt="" />
                  Убрать из заказа
                </button>
                <button
                  v-if="!modal.active"
                  class="toggle-order-item"
                  :class="{ remove: modal.active }"
                  @click="toggleItem(modal)"
                >
                  <img src="../assets/plus.svg" alt="" />
                  Добавить в заказ
                </button>
              </div>
              <div class="col-12 col-md" v-if="orderOne">
                <button
                  class="buy-one-order-item"
                  @click="orderOneItem()"
                  v-if="!success"
                >
                  {{ ordering ? "Обработка..." : "Оформить" }}
                </button>

                <div v-if="success" class="success-text">
                  Заказ
                  <span>#{{ orderOneId }}</span> оформлен!
                </div>
              </div>
              <div class="col-12 col-md" v-if="!success && !modal.stopBuyOne">
                <button
                  class="buy-order-item"
                  :class="{ back: orderOne }"
                  @click="orderOne = !orderOne"
                >
                  {{ orderOne ? "Назад" : "Оформить отдельно" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {
  TOGGLE_ITEM,
  EDIT_MODAL,
  CREATE_ORDER_ONE,
} from "@/store/modules/order";
import { SET_USER_PHONE } from "@/store/modules/user";
import SwitchSelect from "@/pages/Main/components/SwitchSelect";

export default {
  name: "ModalItem",
  components: { SwitchSelect },
  data() {
    return {
      orderOne: false,
      ordering: false,
      success: false,
      form: {
        phone: "",
      },
      errors: [],
      orderOneId: false,
      mask: "+# (###) ###-##-###",
    };
  },
  computed: {
    orderId() {
      return this.$store.getters.orderId;
    },
    modal() {
      return this.$store.getters.modal;
    },
    // cartIds() {
    //     return this.$store.getters.cart.map((v) => {
    //         return v.id;
    //     });
    // },
    // markAsGift() {
    //     let self = this;
    //     let gifts = [];
    //     self.$store.getters.gifts.forEach((value) => {
    //         let requiredItems = value.conditions
    //             .filter((f) => f.type === "item")
    //             .map((i) => {
    //                 return i.id;
    //             });
    //         let activeGift = false;
    //         if (value.any) {
    //             activeGift = requiredItems.some((r) =>
    //                 self.cartIds.includes(r)
    //             );
    //         } else {
    //             activeGift = requiredItems.every((r) =>
    //                 self.cartIds.includes(r)
    //             );
    //         }
    //         if (activeGift) {
    //             gifts.push(value.id);
    //         }
    //     });
    //     return gifts;
    // },
    // isGift() {
    //     return this.markAsGift.includes(this.modal.id);
    // },
  },
  watch: {
    modal() {
      // console.log(this.modal);
      if (this.modal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
        this.success = false;
        this.errors = [];
        this.orderOneId = false;
        this.ordering = false;
      }
    },
    "form.phone"(value) {
      if (value.length <= 18) {
        this.mask = "+# (###) ###-##-###";
      } else {
        this.mask = "+## (###) ###-##-##";
      }
    },
  },
  methods: {
    getVariant(itemId) {
      let item = this.$store.getters.items.find(
        (v) => parseInt(v.id) === parseInt(itemId)
      );
      let res;
      if (item.variant_id) {
        res = item.variants.find((variant) => variant.id == item.variant_id);
      } else {
        res = item;
      }
      return res;
    },
    closeItem() {
      this.$store.commit(EDIT_MODAL, false);
      this.orderOne = false;
    },
    toggleItem(item) {
      if (!this.orderId) {
        this.$store.dispatch(TOGGLE_ITEM, item);
        this.closeItem();
      }
    },
    parsePrice: function (str) {
      return parseFloat(str)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1’");
    },
    orderOneItem() {
      if (!this.ordering && !this.success) {
        this.ordering = true;
        this.errors = [];
        this.$store
          .dispatch(CREATE_ORDER_ONE, {
            itemId: this.getVariant(this.modal.id).id,
            clientId: this.$metrika.getClientID(),
          })
          .then((response) => {
            this.orderOneId = response.id;
            this.success = true;
            this.ordering = false;
            this.$metrika.reachGoal(this.modal.metrikaGoal);
          })
          .catch((errors) => {
            this.errors = errors;
            this.ordering = false;
          });
      }
    },
    changePhone() {
      if (this.errors.includes("phone"))
        this.errors = this.errors.map((v) => v != "phone");
      this.$store.commit(SET_USER_PHONE, this.form.phone);
    },
  },
};
</script>

<style lang="scss" scoped>
.success-text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-weight: 600;
  font-size: 18px;

  span {
    color: #0086fc;
    padding: 0 5px;
  }
}

.item-modal-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000066;
  backdrop-filter: blur(5px);
  top: 0;
  z-index: 99999;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  overflow: auto;

  .item-modal {
    width: 100%;
    max-width: 1100px;
    margin: 0 15px;
    background: linear-gradient(125.93deg, #29292b 8.52%, #222222 90.16%);
    border-radius: 15px;
    //padding: 50px 50px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin: 0px;
      padding: 0px;
      padding-top: 48px;
      border-radius: 0;
      min-height: 100vh;
    }

    .item-modal-close {
      background: transparent;
      border: none;
      position: absolute;
      right: 30px;
      top: 30px;
      svg {
        width: 32px;
        height: 32px;
        fill: #fff;
        transition: 0.2s;
      }
      &:hover {
        svg {
          fill: #be5151;
        }
      }
      @media (max-width: 768px) {
        top: 7px;
        right: 12px;
      }
    }
    .item-modal-body {
      padding: 52px;
      //margin-bottom: 32px;
      transition: 0.5s;
      overflow: hidden;
      border-radius: 16px 16px 0px 0px;
      &:empty {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: "Пусто...";
          font-size: 32px;
          color: #5e5e5e;
          font-weight: 700;
        }
      }
      @media (max-width: 768px) {
        padding: 0px;
        margin-bottom: 32px;
      }
    }
    .item-modal-footer {
      background: linear-gradient(180deg, #34363a 0%, #26282c 100%);
      padding: 32px 52px;
      border-radius: 0px 0px 16px 16px;

      @media (max-width: 768px) {
        padding: 24px;
      }

      .price-text {
        font-size: 35px;
        line-height: 43px;
        margin-right: 15px;
        @media (max-width: 768px) {
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
        }
      }
      .price-value {
        font-weight: 700;
        font-size: 62px;
        color: #06db71;
        line-height: 1;
        @media (max-width: 768px) {
          font-size: 38px;
        }
      }
      button {
        border-radius: 7px;
        font-weight: 600;
        font-size: 20px;
        color: #fefefe;
        width: 100%;
        transition: 0.2s;
        padding: 11px 10px;
        border: 2px solid transparent;

        &.toggle-order-item {
          background: transparent;
          border-color: #2a93f4;
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2a93f4;

          img {
            margin-right: 10px;
          }
          &.remove {
            //background: #d24c4c;
            border-color: #d24c4c;
            color: #d24c4c;
          }
          &:hover {
            background: #3b3b3b;
          }
        }

        &.buy-one-order-item {
          border-color: #02c071;
          background: #02c071;
          &.remove {
            background: #68f1b7;
          }
          &:hover {
            background: #01985a;
          }
        }
        &.buy-order-item {
          background: #2a93f4;
          &:hover {
            background: #1e7ad0;
          }
          &.back {
            background: transparent;
            border-color: #2a93f4;
          }
        }
        @media (max-width: 768px) {
          margin-top: 15px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    overflow: auto;
    display: block;
  }

  .order-one {
    margin-bottom: 14px;

    @media (max-width: 768px) {
      margin-bottom: 0px;
      margin-top: 14px;
    }
    input {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 12px 50px 12px 20px;
      color: #505050;
      font-size: 18px;
      width: 100%;
      border: 1px solid transparent;
      transition: 0.3s;

      &::placeholder {
        font-size: 18px;
        color: #505050;
      }

      &.error {
        border-color: #ff4f4f;
        background: #ffe0e0;
      }
    }
  }
}

.sale-info {
  text-align: center;
  //padding-top: 5px;
  font-size: 13px;
  color: #a7a7a7;

  &.pb-25px {
    padding-bottom: 25px;
  }
}

.order-help {
  margin-top: 20px;
  color: #909090;
  font-size: 12px;
  line-height: 16px;
  img {
    margin-right: 13px;
  }
}
</style>

<style lang="scss">
/*! purgecss start ignore */
/* purgecss ignore */
.im-title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 6px;
  line-height: 34px;
  @media (min-width: 768px) {
    padding-right: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 16px;
  }
}
/* purgecss ignore */
.im-subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  background-image: linear-gradient(to left, #ffa353, #d95e00);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: 0 0 transparent;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    padding: 0 16px;
    font-size: 22px;
  }
}
/* purgecss ignore */
.im-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.im-delivery-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0086fc;
}
.im-delivery-icon {
  width: 42px;
}
.im-delivery-container {
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}
.im-image {
  max-width: 100%;
  border-radius: 15px;

  @media (max-width: 768px) {
    max-width: 360px;
    display: block;
    margin: 0 auto 32px;
  }

  @media (max-width: 360px) {
    border-radius: 0px;
  }
}
/*! purgecss end ignore */
</style>
