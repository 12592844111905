<template>
  <!--    <p>
        Дорогое ароматное сырье, например шотландский подкопчённый солод
        («дымный» виски) или концентрат французских сортов винограда (коньяк),
        дорого перегонять на «безвкусный» спирт.
    </p>
    <p>
        Для сохранения аромата ингредиентов (даже бабушкиного варенья) в напитке
        просто поставьте меньше тарелок! Рекомендуется ставить не более 6-7 шт,
        чтобы не «отсечь» вкус ингредиентов.
    </p>
    <p>
        Медь связывает соединения серы, позволяя получать благородные дистилляты
        без запаха самогона.
        <a
            href="#"
            class="link"
            @click.prevent="$root.proxy.AppFooter.$refs.modalWhisky.open()"
            >Известные винокурни</a
        >
        Jack Daniels, Baccardi, Hennessy, Jameson используют медь для перегонки.
    </p>-->
  <p>
    Ароматное сырье (солод, фрукты, виноград, старое варенье, мёд) разумнее
    превратить в виски или коньяк, а не в спирт. Виски и бренди получают простой
    перегонкой, а чтобы убрать “самогонные” нотки, выдерживают в дубовых бочках.
    А если не хочется ждать несколько лет?
  </p>
  <p>
    Золотая середина между перегонкой и ректификацией - дистилляция на 4-6
    тарелках. Так можно и эффективно отделить примеси, и сохранить аромат!
    Тарелки имеют в 3 раза большую удерживающую способность, чем насадка, и
    гораздо лучше сдерживают «хвосты» на короткой колонне. Именно поэтому
    тарельчатые колонны традиционно считаются оборудованием №1 для ароматных
    дистиллятов. А ароматные дистилляты (а не спирт): виски, кальвадос, бренди,
    коньяк - традиционно считаются напитками успешных людей!
  </p>
</template>

<script>
export default {
  name: "WhiskyBrandiText",
};
</script>

<style lang="scss" scoped>
.link {
  color: #488eff;
  text-decoration: underline;
}
</style>
