<template>
  <div class="paragraph" :class="{ background, odd }">
    <h2 class="paragraph-title">{{ title }}</h2>
    <h3 class="paragraph-subtitle" :class="color">
      {{ subtitle }}
    </h3>
    <div class="paragraph-text"><slot></slot></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Paragraph",
  props: {
    title: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    background: {
      default: true,
    },
    odd: {
      default: false,
    },
    color: {
      default: "orange",
    },
  },
};
</script>

<style lang="scss">
.paragraph {
  @media (min-width: 1200px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  &:not(.background) {
    @media (min-width: 768px) and (max-width: 991px) {
      //padding: 70px 0;
    }
  }

  &.background {
    @media (min-width: 768px) {
      position: relative;

      margin-right: 50px;
      margin-left: 0;
      &.odd {
        margin-left: 50px;
        margin-right: 0;
      }

      &:before {
        content: "";
        background: linear-gradient(
          129.22deg,
          rgba(21, 21, 22, 0.9) 0%,
          rgba(50, 50, 51, 0.9) 97.03%
        );
        border-radius: 17px;
        width: calc(100% + 104px);
        height: calc(100% + 104px);
        left: -52px;
        top: -52px;
        display: block;
        position: absolute;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 50px 0;
      &:before {
        height: calc(100% + -80px);
        top: 62px;
      }
    }
  }
}
.paragraph-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 6px;
  z-index: 3;
  position: relative;

  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 34px;
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
}

.paragraph-subtitle {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 52px;
  z-index: 3;
  position: relative;

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    text-align: center;
  }

  &.blue {
    color: #0086fc;
  }

  &.orange {
    background-image: linear-gradient(to left, #ffa353, #d95e00);
    color: transparent;
    /* purgecss start ignore */
    background-clip: text;
    -webkit-background-clip: text;
    text-shadow: 0 0 transparent;
    /* purgecss end ignore */
  }
}

.paragraph-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  z-index: 2;
  position: relative;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    position: relative;
  }

  p {
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
