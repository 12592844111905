<template>
  <!--    <p>
          18 ступеней перегонки DomSpirt обеспечивают крепость >96% об., а диаметр
          3” гарантирует скорость в 2 раза выше 2"-конкурентов при нагреве до 3.5
          кВт.
      </p>
      <p>
          Медь (9 медных тарелок в колонне) улучшает аромат алкоголя (даже
          обычного сахарного самогона) за счет связывания сернистых соединений -
          доказано учёными Эдинбургского университета
          <a @click.prevent="openPDF()" class="link" href="#">(исследование)</a>
      </p>
      <p>
          Себестоимость спирта из сахара (5 кг сахара/100 г дрожжей/20 л воды) не
          превышает 50 руб за 0,5 л бутылку при цене на сахар 70 р/кг.
      </p>-->
  <p>
    DomSpirt 2 позволяет получать чистый спирт с высокой степенью укрепления за
    счёт многоступенчатой системы перегонки. Диаметр 3” дюйма гарантирует
    скорость отбора в 2 раза выше 2"-конкурентов.
  </p>
  <p>
    Тарельчатая система Ds2 позволяет чётко и просто следовать рецепту, получая
    напитки как в руководстве или на видео блогеров. Короткая царга (6 тарелок)
    ставится, если нужен ароматный дистиллят, и обе (18 тарелок) если нужен
    спирт. Чёткость крепости крайне важна, т.к. превышение всего на 1% об. уже
    приведёт к тому, что, например, кальвадос, будет пахнуть не яблоками, а
    спиртом. Высокую точность изготовления тарелок DS 2 обеспечивают российские
    ЧПУ-станки с немецкой автоматикой Sсhneider.
  </p>
</template>

<script>
// import { Fancybox } from "@fancyapps/ui";

export default {
  name: "ClearSpirtText",
  components: {},
  methods: {
    openPDF() {
      this.$root.proxy.AppFooter.$refs.modalSpirt.open();
      // Fancybox.show([
      //     {
      //         src: "https://test2.lxsthl.ru/static/file.pdf",
      //         width: "100%",
      //         height: "80%",
      //         // autoSize: true,
      //         type: "iframe",
      //     },
      // ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #488eff;
  text-decoration: underline;
}
</style>
