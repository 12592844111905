<template>
  <p>
    Наслаждение должно быть утонченным. В водке (спирте) насладиться можно разве
    что опьянением. Дистилляты (виски, бренди, коньяк) уже позволяют получать
    удовольствие от вкуса и аромата напитка.
  </p>
  <p>
    Самогоноварение добавляет к этому удовольствие ещё и от процесса созидания.
    А создание напитков на прозрачной тарельчатой колонне позволяет довести
    визуально-эстетическое наслаждение до совершенства! На завораживающий
    процесс перегонки на каждой ступени тарельчатой колонны можно любоваться, и,
    возможно, для Ваших домашних (например, жены или кота) Ваше увлечение
    винокурением будет восприниматься как дополнительное украшение кухни! (<a
      href="javascript:void(0)"
      @click="openVideo(BASE_URL + '/media/video2.mp4')"
      class="link"
      >Видео</a
    >)
  </p>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";

const BASE_URL =
  process.env.NODE_ENV === "production" ? process.env.BASE_URL : "";

export default {
  name: "AestheticText",
  components: {},
  data: () => ({
    BASE_URL,
  }),
  methods: {
    openVideo(src) {
      function gcd(a, b) {
        return b == 0 ? a : gcd(b, a % b);
      }

      this.getVideoDimensionsOf(src).then((video) => {
        let w = video.width;
        let h = video.height;
        let r = gcd(w, h);

        Fancybox.show(
          [
            {
              src: src,
              ratio: w / r / (h / r),
              width: video.width,
              height: video.height,
            },
          ],
          {
            preload: 0,
          }
        );
      });
    },
    getVideoDimensionsOf(url) {
      return new Promise((resolve) => {
        // create the video element
        const video = document.createElement("video");

        // place a listener on it
        video.addEventListener(
          "loadedmetadata",
          () => {
            // retrieve dimensions
            const height = video.videoHeight;
            const width = video.videoWidth;

            // send back result
            resolve({ height, width });
          },
          false
        );

        // start download meta-datas
        video.src = url;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  color: #488eff;
  text-decoration: underline;
}
</style>
