<template>
  <div class="sw-box" :class="{ disabled: disabled }">
    <div class="sw-nav">
      <button
        class="sw-button"
        :class="[
          ...v.classList,
          name,
          { animate: v.active, disabled: disabled },
        ]"
        :ref="'sw' + name + v.key"
        :style="v.style"
        @click="onClick(v)"
        v-for="v in values"
        :key="v.key"
      >
        {{ v.value }}
      </button>
    </div>
    <!--    <div class="ps-1">тарелок</div>-->
  </div>
</template>

<script>
import { SET_VARIANT } from "@/store/modules/order";

export default {
  name: "SwitchSelect",
  props: {
    variants: {
      default: [],
    },
    item: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  components: {},
  data: () => ({
    name: "_id" + Math.random().toString(16).slice(2),
    // values: [
    //   {
    //     id: "_id" + Math.random().toString(16).slice(2),
    //     value: "aaa",
    //     classList: [],
    //   },
    //   {
    //     id: "_id" + Math.random().toString(16).slice(2),
    //     value: "bb",
    //     classList: [],
    //   },
    //   {
    //     id: "_id" + Math.random().toString(16).slice(2),
    //     value: "ccccc",
    //     classList: [],
    //   },
    // ],
    values: [],
    unsubscirbe: null,
    mobile: false,
  }),
  methods: {
    onClick(v) {
      this.click(v);

      this.$store.dispatch(SET_VARIANT, {
        variant: this.values.find((v) => v.active),
        item: this.item,
      });
    },
    click(v, skip = false) {
      if (this.disabled && !skip) return;
      const el = this.$refs["sw" + this.name + v.key][0];
      // console.log(el);
      const prevEl = document.querySelector(
        "." + this.name + ".sw-button.animate"
      );
      // console.log(prevEl);
      const x =
        prevEl == null
          ? "0%"
          : prevEl.getBoundingClientRect().x -
            el.getBoundingClientRect().x +
            "px";
      // const w2 = window.getComputedStyle(el).width;
      const w1 = prevEl == null ? 0 : window.getComputedStyle(prevEl).width;

      this.values = this.values.map((v) => {
        // v.classList = v.classList.filter((e) => e !== "animate");
        v.active = false;
        return v;
      });
      // buttons.forEach((button) => {
      //   button.classList.remove("animate");
      // });
      // el.style.setProperty("--x", x);
      // el.style.setProperty("--w1", w1);
      // el.style.setProperty("--w2", "100%");
      v.style = {
        ...v.style,
        "--x": x,
        "--w1": w1,
        "--w2": "100%",
      };
      v.active = true;
      // v.classList.push("animate");

      this.$emit("select", {
        variant: this.values.find((v) => v.active),
        item: this.item,
      });
    },
    select(v) {
      v.style = {
        ...v.style,
        "--x": "100%",
        "--w1": "0",
        "--w2": "100%",
      };
      v.active = true;
      // v.classList.push("animate");
    },
  },
  mounted() {
    if (window.innerWidth <= 991) this.mobile = true;
    this.values = this.variants.map((v) => ({
      id: v.id,
      active: false,
      key: "_id" + Math.random().toString(16).slice(2),
      value: this.mobile ? v.name.short : v.name.full,
      classList: [],
      style: v.style,
    }));
    this.select(this.values.find((v) => v.id === this.item.variant_id));
    this.unsubscirbe = this.$store.subscribeAction((action) => {
      if (
        action.type === SET_VARIANT &&
        this.values.find((v) => v.id === action.payload.variant.id)
      ) {
        // this.setVariant();
        this.click(
          this.values.find((v) => v.id === action.payload.variant.id),
          true
        );
      }
    });
  },
  beforeUnmount() {
    this.unsubscirbe();
  },
};
</script>

<style scoped lang="scss">
$border-radius: 10px;
$border-radius-mobile: 6px;
$background-color: transparent;
$border-color: #444444;
$animation-duration: 0.3s;
$item-padding: 0px;
//$background-color-selected: gold;
//$background-image-selected: linear-gradient(to right, #a2a2a2, #8a8a8a);
//$background-image-selected: linear-gradient(to right, #ffb753, #d95e00);
// FFB753 -> D95E00

.sw-box {
  font-size: 13px;
  filter: grayscale(0);
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  &.disabled {
    filter: grayscale(1);
    opacity: 0.6;
  }
}

.sw-nav {
  display: inline-block;
  position: relative;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  @media (max-width: 767px) {
    border-radius: $border-radius-mobile;
  }

  //&::before {
  //  content: "";
  //  position: absolute;
  //  background-color: gold;
  //  width: 100%;
  //  height: 100%;
  //  left: 0;
  //  top: 0;
  //  z-index: -3;
  //}
}

.sw-button {
  //margin: 2vmin;
  background-color: transparent;
  position: relative;
  border: none;
  //color: var(--color-to);
  font-weight: 600;

  background-image: linear-gradient(
    to right,
    var(--color-from),
    var(--color-to)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  transition: color $animation-duration;
  padding: 3px 8px;

  &:first-of-type {
    &::before {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      @media (max-width: 767px) {
        border-top-left-radius: $border-radius-mobile;
        border-bottom-left-radius: $border-radius-mobile;
      }
    }
  }

  &:last-of-type {
    &::before {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      @media (max-width: 767px) {
        border-top-right-radius: $border-radius-mobile;
        border-bottom-right-radius: $border-radius-mobile;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $background-color;
    z-index: -2;
    border: none;
  }

  &.animate {
    color: var(--color-selected);
    &::after {
      content: "";
      position: absolute;
      //background-color: $background-color-selected;
      //background: $background-image-selected;
      //background: var(--bg);
      background: linear-gradient(to right, var(--color-from), var(--color-to));
      //transition: --color-from 3s, --color-to 3s;
      width: 100%;
      height: 100%;
      animation-name: move;
      animation-duration: $animation-duration;
      animation-fill-mode: forwards;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: $border-radius;
      @media (max-width: 767px) {
        border-radius: $border-radius-mobile;
      }

      max-height: calc(100% - ($item-padding * 2));
      max-width: calc(100% - ($item-padding * 2));
      margin: $item-padding;
    }
  }

  &.disabled {
    //cursor: default;
  }

  @media (max-width: 767px) {
    padding: 0px 6px;
  }
}

@keyframes move {
  0% {
    transform: translateX(var(--x));
    width: var(--w1);
  }
  100% {
    transform: translateX(0);
    width: var(--w2);
  }
}

@keyframes animate {
  0% {
  }
  100% {
    //background-color: $background-color-selected;
    background: linear-gradient(to right, var(--color-from), var(--color-to));
  }
}
</style>
