import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 1736,
  sort_id: 204,
  display: true,
  subitem: 2550,
  metrikaGoal: "ten",
  name: {
    full: "ТЭН 3.5 кВт 220 В",
    short: "ТЭН 3.5 кВт",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/ten-35/html"))
  ),
  price: 1990,
  layers: {
    "*": [
      {
        layer: 52,
        name: "dno_ten_zoom",
        hideIfNotActive: ["dno_zoom"],
        url: require("@/pages/Main/assets/order5/ten_dno.png") + webp(),
      },
      {
        layer: 48,
        name: "ten_zoom",
        url: require("@/pages/Main/assets/order5/ten.png") + webp(),
      },
      {
        layer: 52,
        name: "dno_ten",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/ten_dno.png") + webp(),
      },
      {
        layer: 48,
        name: "ten",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/ten.png") + webp(),
      },
    ],
  },
  count: 1,
  active: false,
};
