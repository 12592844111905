import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2377,
  sort_id: 205,
  display: true,
  subitem: 2550,
  metrikaGoal: "reg",
  name: {
    full: "Регулятор-стабилизатор",
    short: "Регулятор",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/regulator/html"))
  ),
  price: 3990,
  layers: {
    "*": [
      {
        layer: 52,
        name: "regul_zoom",
        url: require("@/pages/Main/assets/order5/regul.png") + webp(),
      },
      {
        layer: 52,
        name: "regul",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/regul.png") + webp(),
      },
    ],
  },
  count: 1,
  active: false,
};
