import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2550,
  sort_id: 200,
  display: true,
  hidePlus: true,
  hide_mobile: true,
  metrikaGoal: "kub",
  name: {
    full: "Перегонный куб 37 л",
    short: "Куб 37 л",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/kub/html"))
  ),
  price: 14990,
  layers: {
    0: [
      {
        layer: 50,
        name: "tank_zoom",
        hide: [],
        url: require("@/pages/Main/assets/order5/tank.png") + webp(),
      },
      {
        layer: 51,
        name: "tank_up_nerj",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/tank_up_nerj.png") + webp(),
      },
      {
        layer: 50,
        name: "tank",
        hide: [],
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/tank.png") + webp(),
      },
    ],
    1: [
      {
        layer: 50,
        name: "tank_zoom",
        hide: [],
        url: require("@/pages/Main/assets/order5/tank.png") + webp(),
      },
      {
        layer: 51,
        name: "tank_up_nerj_size",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url:
          require("@/pages/Main/assets/order4/tank_up_nerj_size.png") + webp(),
      },
      {
        layer: 50,
        name: "tank",
        hide: [],
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/tank.png") + webp(),
      },
      // {
      //   layer: 50,
      //   name: "tank",
      //   url: require("@/pages/Main/assets/order4/tank.png") + webp(),
      // },
      // {
      //   layer: 51,
      //   name: "tank_up_nerj_size",
      //   hide: ["tank_up_nerj"],
      //   url:
      //     require("@/pages/Main/assets/order4/tank_up_nerj_size.png") + webp(),
      // },
    ],
  },
  image: {
    url: "",
    layer: 1,
  },
  count: 1,
  active: true,
};
