// import axios from "@/api/axios";

import { SET_PROMO } from "@/store/modules/order";

const getters = {
  user(state) {
    return state.user;
  },
};

const state = {
  user: {
    phone: "",
    history: [],
    ip: null,
  },
};

export const SET_USER_PHONE = "setUserPhone";
export const SET_USER_IP = "setUserIp";
export const SET_USER_UTM = "setUserUtm";
export const ADD_USER_UTM = "addUserUtm";
export const SET_USER_HISTORY = "setUserHistory";
export const ADD_USER_HISTORY = "addUserHistory";

const mutations = {
  [SET_USER_PHONE](state, payload) {
    state.user.phone = payload;
  },
  [SET_USER_IP](state, payload) {
    state.user.ip = payload;
  },
  [SET_USER_UTM](state, payload) {
    state.user.utm = payload;
  },
  [ADD_USER_UTM](state, payload) {
    state.user.utm.push(payload);
  },
  [SET_USER_HISTORY](state, payload) {
    state.user.history = payload;
  },
  [ADD_USER_HISTORY](state, payload) {
    state.user.history.push(payload);
    localStorage.setItem("history", btoa(JSON.stringify(state.user.history)));
  },
};

export const UPDATE_USER_HISTORY = "updateUserHistory";
export const LOAD_USER_HISTORY = "loadUserHistory";

const actions = {
  [UPDATE_USER_HISTORY](context) {
    fetch("https://www.cloudflare.com/cdn-cgi/trace")
      .then((response) => response.text())
      .then((data) => {
        data = data
          .trim()
          .split("\n")
          .reduce(function (obj, pair) {
            pair = pair.split("=");
            return (obj[pair[0]] = pair[1]), obj;
          }, {});

        const query = decodeURI(window.location.search)
          .replace("?", "")
          .split("&")
          .map((param) => param.split("="))
          .reduce((values, [key, value]) => {
            values[key] = value;
            return values;
          }, {});

        if (query.p) {
          context.dispatch(SET_PROMO, query.p);
        }

        if (data) {
          context.commit(SET_USER_IP, data.ip);
        }

        window.history.replaceState(
          window.history.state,
          null,
          window.location.pathname + window.location.hash
        );

        context.commit(ADD_USER_HISTORY, {
          page: {
            hash: window.location.hash,
            host: window.location.host,
            hostname: window.location.hostname,
            href: window.location.href,
            origin: window.location.origin,
            pathname: window.location.pathname,
            port: window.location.port,
            protocol: window.location.protocol,
            search: window.location.search,
          },
          trace: data,
          query: query,
          time: Math.floor(Date.now() / 1000),
        });
      });
  },
  [LOAD_USER_HISTORY](context) {
    if (localStorage.getItem("history")) {
      let cookie = localStorage.getItem("history");
      // console.log(JSON.parse(atob(cookie)));
      context.commit(SET_USER_HISTORY, JSON.parse(atob(cookie)));
      // console.log(
      //     "%cLocal storage is found",
      //     "color: green; font-wight: bold;"
      // );
    } else {
      // console.log(
      //     "%cLocal storage is NOT found",
      //     "color: orange; font-wight: bold;"
      // );
    }
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
