// function elementInViewport(el) {
//   if (!el) return false;
//   let rect = el.getBoundingClientRect();
//   let elemTop = rect.top + window.innerHeight / 2;
//   let elemBottom = rect.bottom - window.innerHeight / 3;
//
//   // Only completely visible elements return true:
//   // let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
//   // Partially visible elements return true:
//   let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
//   // console.log(el, isVisible);
//   return isVisible;
// }

// function doScrolling(element, duration) {
//   var startingY = window.pageYOffset;
//   var start;
//
//   // Bootstrap our animation - it will get called right before next frame shall be rendered.
//   window.requestAnimationFrame(function step(timestamp) {
//     if (!start) start = timestamp;
//     // Elapsed milliseconds since start of scrolling.
//     var time = timestamp - start;
//     // Recalc
//     console.log(element.offsetTop);
//     var diff = element.offsetTop - startingY;
//     // Get percent of completion in range [0, 1].
//     var percent = Math.min(time / duration, 1);
//
//     window.scrollTo(0, startingY + diff * percent);
//
//     // Proceed with animation as long as we wanted it to.
//     if (time < duration) {
//       window.requestAnimationFrame(step);
//     }
//   });
// }

const scrollToElement = function (el) {
  // doScrolling(el, 3000);
  // const y = el.getBoundingClientRect().top + window.scrollY;
  // console.log(el.offsetTop);
  // let offsetTop = el.offsetTop;
  window.scroll({
    top: el.offsetTop,
    behavior: "smooth",
  });

  function getOffsetTop() {
    // console.log(el.offsetTop, window.scrollY);
    if (window.scrollY < el.offsetTop - 30) {
      // window.scroll(0, el.offsetTop - 30);
      window.scroll({
        top: el.offsetTop - 30,
        behavior: "auto",
      });
      setTimeout(getOffsetTop, 500);
    } else {
      // console.error(
      //   window.scrollY,
      //   el.offsetTop,
      //   window.scrollY < el.offsetTop
      // );
    }
  }

  setTimeout(getOffsetTop, 500);
  // let direction = el.offsetTop > window.scrollY;
  // console.log(el.offsetTop, window.scrollY, elementInViewport(el), direction);
  // if (direction) {
  //   console.log(window.scrollY);
  //   // window.scrollTo({
  //   //   top: window.scrollY + 100,
  //   //   left: 0,
  //   //   behavior: "smooth",
  //   // });
  //   for (let i; i < 10; i++) {
  //     setTimeout(() => {
  //       console.log(window.scrollY);
  //       window.scrollTo({
  //         top: window.scrollY + 100,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }, 1000 * i);
  //   }
  //   // while (!elementInViewport(el)) {
  //   //   window.scrollTo(0, window.scrollY + 100);
  //   // }
  //   // to down
  // } else {
  //   // to sky
  // }
  // console.dir(el);
};

export default scrollToElement;
