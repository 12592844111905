import webp from "@/functions/webp";
import { defineAsyncComponent, markRaw } from "vue";

export default {
  id: 2370,
  sort_id: 202,
  display: true,
  subitem: 2550,
  metrikaGoal: "fd",
  name: {
    full: "Фальш-дно для зерновой браги",
    short: "Фальш-дно",
  },
  component: markRaw(
    defineAsyncComponent(() => import("@/store/modules/items/falsh-dno/html"))
  ),
  price: 990,
  layers: {
    "*": [
      {
        layer: 51,
        name: "dno_zoom",
        url: require("@/pages/Main/assets/order5/dno.png") + webp(),
      },
      {
        layer: 51,
        name: "dno",
        hideIfNotActive: ["deflegmator_carga_12", "deflegmator_carga_12_size"],
        url: require("@/pages/Main/assets/order4/dno.png") + webp(),
      },
    ],
  },
  count: 1,
  active: false,
};
