<template>
  <nav
    class="navbar navbar-expand-md navbar-dark fixed-top"
    :class="{ 'bg-darkest': showMobileMenu, show: show }"
    v-click-outside="hideMenu"
    style="display: none"
  >
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMenu"
      >
        <img v-lazy="{ src: require('../assets/menu.svg') }" alt="" />
      </button>
      <router-link
        :to="{ name: 'main' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <a
          v-if="!isActive"
          class="navbar-brand"
          :class="[isActive && 'active', isExactActive && 'active']"
          :href="href"
          @click="navigate"
        >
          <img v-lazy="{ src: logo }" alt="" />
        </a>

        <a href="#" v-else class="navbar-brand">
          <img v-lazy="{ src: logo }" alt="" />
        </a>
      </router-link>

      <router-link
        :to="{ name: 'main', hash: '#order' }"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <a
          v-if="!isActive"
          class="nav-link order-button d-md-none"
          :class="[isActive && 'active', isExactActive && 'active']"
          :href="href"
          @click="navigate"
          >Заказ</a
        >

        <a href="#order" v-else class="nav-link order-button d-md-none"
          >Заказ</a
        >
      </router-link>
      <div class="navbar-app" :class="{ show: showMobileMenu }">
        <ul class="navbar-nav ms-auto mb-2 mb-md-0">
          <router-link
            :to="{ name: 'main' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item d-md-none">
              <a
                class="nav-link"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
              >
                Главная
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'characteristics' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item">
              <a
                class="nav-link"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
              >
                Характеристики
              </a>
            </li>
          </router-link>
          <router-link
            :to="{ name: 'reviews' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item">
              <a
                class="nav-link"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
              >
                Отзывы
              </a>
            </li>
          </router-link>
          <!--                    <router-link
                        :to="{ name: 'deliveries' }"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="[
                                    isActive && 'active',
                                    isExactActive && 'active',
                                ]"
                                :href="href"
                                @click="navigate"
                            >
                                Доставка
                            </a>
                        </li>
                    </router-link>-->
          <router-link
            :to="{ name: 'main', hash: '#delivery' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item">
              <a
                v-if="!isActive"
                class="nav-link"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
                >Доставка</a
              >

              <a href="#delivery" v-else class="nav-link">Доставка</a>
            </li>
          </router-link>
          <!--                    <router-link
                        :to="{ name: 'comparison' }"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        custom
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="[
                                    isActive && 'active',
                                    isExactActive && 'active',
                                ]"
                                :href="href"
                                @click="navigate"
                            >
                                Сравнение
                            </a>
                        </li>
                    </router-link>-->
          <router-link
            :to="{ name: 'contacts' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item">
              <a
                class="nav-link"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
                >Контакты</a
              >
            </li>
          </router-link>
          <router-link
            :to="{ name: 'main', hash: '#order' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li class="nav-item d-none d-md-inline">
              <a
                v-if="!isActive"
                class="nav-link order-button"
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                @click="navigate"
                >Заказ</a
              >

              <a href="#order" v-else class="nav-link order-button">Заказ</a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import { Collapse } from "bootstrap";

// const easingFunctions = {
//     // no easing, no acceleration
//     linear: (t) => t,
//     // accelerating from zero velocity
//     easeInQuad: (t) => t * t,
//     // decelerating to zero velocity
//     easeOutQuad: (t) => t * (2 - t),
//     // acceleration until halfway, then deceleration
//     easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
//     // accelerating from zero velocity
//     easeInCubic: (t) => t * t * t,
//     // decelerating to zero velocity
//     easeOutCubic: (t) => --t * t * t + 1,
//     // acceleration until halfway, then deceleration
//     easeInOutCubic: (t) =>
//         t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
//     // accelerating from zero velocity
//     easeInQuart: (t) => t * t * t * t,
//     // decelerating to zero velocity
//     easeOutQuart: (t) => 1 - --t * t * t * t,
//     // acceleration until halfway, then deceleration
//     easeInOutQuart: (t) =>
//         t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
//     // accelerating from zero velocity
//     easeInQuint: (t) => t * t * t * t * t,
//     // decelerating to zero velocity
//     easeOutQuint: (t) => 1 + --t * t * t * t * t,
//     // acceleration until halfway, then deceleration
//     easeInOutQuint: (t) =>
//         t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
// };

export default {
  name: "AppMenu",
  data() {
    return {
      showMobileMenu: false,
      show: false,
      mobile: false,

      requestAnimationFrame: null,
      logo: require("../assets/logo.svg"),
    };
  },
  methods: {
    toggleMenu() {
      if (this.showMobileMenu) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
    },
    showMenu() {
      if (this.mobile) {
        this.showMobileMenu = true;
        document.body.classList.add("modal-open");
      }
    },
    hideMenu() {
      if (this.mobile) {
        this.showMobileMenu = false;
        document.body.classList.remove("modal-open");
      }
    },
    // scrollTo(target) {
    //     const start = window.scrollY || window.pageYOffset;
    //     const time = Date.now();
    //     const duration =
    //         Math.abs(start - document.getElementById(target).offsetTop) / 3;
    //
    //     (function step() {
    //         var dx = Math.min(1, (Date.now() - time) / duration);
    //         var pos =
    //             start +
    //             (document.getElementById(target).offsetTop - start) *
    //                 easingFunctions.easeInOutCubic(dx);
    //
    //         window.scrollTo(0, pos);
    //
    //         if (dx < 1) {
    //             requestAnimationFrame(step);
    //         }
    //     })();
    // },
    // scrollTo(e) {},
    // scrollDone(element) {
    //     setTimeout(() => {
    //         this.$scrollTo(element, 100, {
    //             offset: -60,
    //             lazy: false,
    //         });
    //     }, 800);
    // },
    elementInViewport(el) {
      if (window.innerWidth > 767) return false;
      if (!el) return false;
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top + window.innerHeight / 2;
      let elemBottom = rect.bottom - window.innerHeight / 3;

      // Only completely visible elements return true:
      // let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
      // Partially visible elements return true:
      let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
      // console.log(el, isVisible);
      return isVisible;
    },
  },
  watch: {
    "$route.name"() {
      this.show =
        (window.scrollY > window.innerHeight * 0.1 ||
          window.innerWidth > 767 ||
          this.$route.name !== "main") &&
        !this.elementInViewport(document.getElementById("order"));
    },
  },
  mounted() {
    let self = this;
    const navLinks = document.querySelectorAll(".nav-item");
    // const menuToggle = document.getElementById("navbarCollapse");
    // const bsCollapse = new Collapse(menuToggle);
    navLinks.forEach((l) => {
      l.addEventListener("click", () => {
        self.hideMenu();
      });
    });
    window.addEventListener("scroll", () => {
      this.show =
        (window.scrollY > window.innerHeight * 0.1 ||
          window.innerWidth > 767 ||
          this.$route.name !== "main") &&
        !this.elementInViewport(document.getElementById("order"));
    });
    this.show =
      (window.scrollY > window.innerHeight * 0.1 ||
        window.innerWidth > 767 ||
        this.$route.name !== "main") &&
      !this.elementInViewport(document.getElementById("order"));
    // setTimeout(() => {
    //     document.getElementById("navbarCollapse").classList.remove("show");
    // }, 100);
    if (window.innerWidth < 768) this.mobile = true;
    if (window.innerWidth >= 768 && window.innerWidth < 991)
      this.logo = require("../assets/logomini.svg");

    this.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame;
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .navbar-app {
    height: 0vh;
    top: 3.5rem;
    transition: 0.3s;
    overflow: hidden;

    &.show {
      height: 100vh;
    }
  }
}
.order-button {
  background: #0086fc;
  border-radius: 100px;
  color: #fff !important;
  font-weight: 500;
  font-size: 17px;
  padding: 8px 32px !important;
  display: block;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0 !important;
    padding: 5px 25px !important;
  }

  &:hover {
    background: #0070d7;
    text-decoration: none;
  }
}
.nav-item {
  &:not(:last-child) {
    margin-right: 28px;
    @media (max-width: 991px) {
      margin-right: 16px;
    }

    @media (min-width: 767px) {
      .nav-link {
        text-shadow: 0 0 10px black;
        &:hover {
          color: rgba(255, 255, 255, 0.95);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .nav-link {
      font-size: 25px;
      font-weight: 500;
      color: rgb(255 255 255 / 81%);
    }
  }
}

.navbar-toggler {
  border: none;
}

.nav-item {
  @media (max-width: 767px) {
    padding: 0 15px;
    margin-right: 0 !important;
  }
}
.navbar-brand {
  img {
    @media (max-width: 767px) {
      height: 30px;
    }
    @media (max-width: 360px) {
      width: 116px;
    }
  }
}
.bg-darkest {
  background-color: #000000c7;
}
.navbar {
  transition: 1s;
  top: -100%;
  display: flex !important;

  &.show {
    top: 0;
  }
}

//.header-enter-active,
//.header-leave-active {
//    transition: all 0.5s;
//}
//
//.header-enter, .header-leave-to /* .fade-leave-active до версии 2.1.8 */ {
//    //opacity: 0;
//    top: -3.5rem;
//}
</style>
