import { createStore } from "vuex";
import order from "@/store/modules/order";
import user from "@/store/modules/user";
import tracking from "@/store/modules/tracking";
import metrics from "@/store/modules/metrics";

export default createStore({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    order,
    user,
    tracking,
    metrics,
  },
});
