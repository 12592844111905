<template>
  <div class="container mt-2 app-footer">
    <div class="row" v-if="mobile">
      <div class="col-12">
        <div class="footer-menu-mobile">
          <div>
            <router-link :to="{ name: 'characteristics' }" class="menu-link"
              >Характеристики
            </router-link>
          </div>
          <div>
            <router-link
              :to="{ name: 'main', hash: '#delivery' }"
              class="menu-link"
              >Доставка по России
            </router-link>
          </div>
          <div>
            <router-link :to="{ name: 'contacts' }" class="menu-link"
              >Контакты
            </router-link>
          </div>
          <!--          <div>
                      &lt;!&ndash;                        <a
                                      href="https://t.me/domspirt"
                                      target="_blank"
                                      class="menu-link"
                                  >
                                      Канал
                                      <span class="telegram-color">
                                          <img src="./assets/tg.svg" alt="" /> Telegram
                                      </span>
                                  </a>&ndash;&gt;
                      <a href="https://t.me/domspirt" target="_blank" class="social-link">
                        <span>Канал</span>
                        <img src="./assets/tg.svg" alt="" />
                        <span class="tg">Telegram</span>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCWXYvEq293028RpWhU6lqzA"
                        target="_blank"
                        class="social-link"
                      >
                        <img src="./assets/yt.svg" alt="" />
                        <span class="yt">YouTube</span>
                      </a>
                      <a
                        href="https://vk.com/domspirt"
                        target="_blank"
                        class="social-link"
                      >
                        <img src="./assets/vk.svg" alt="" />
                        <span class="vk">Вконтакте</span>
                      </a>
                    </div>-->
        </div>
      </div>
      <div class="col-12">
        <div class="row justify-content-center footer-mobile">
          <!--                    <div class="col-auto ps-0">
                                  <router-link
                                      :to="{ name: 'main', hash: '#tracking' }"
                                      class="footer-link"
                                  >
                                      Отследить заказ
                                  </router-link>
                              </div>-->
          <!--                    <div class="col-auto ps-0">
                                  <a href="#" class="footer-link"
                                      >Для юрлиц оплата с РС</a
                                  >
                              </div>-->
          <div class="col-12 text-center ps-0">
            <a
              href="#"
              class="footer-link"
              @click.prevent="$refs.modalPolicy.open()"
              >Политика конфиденциальности</a
            >
          </div>
          <div class="col-auto ps-0">
            <a
              href="#"
              class="footer-link"
              @click.prevent="$refs.orderTracking.open()"
              >Отслеживание заказа</a
            >
          </div>
          <!--                    <div class="col-auto ps-0">
                                  <a
                                      href="#"
                                      class="footer-link"
                                      @click.prevent="$refs.modalOferta.open()"
                                      >Не является офертой</a
                                  >
                              </div>-->
        </div>
      </div>
      <div class="col-12">
        <div
          class="phone-box mx-0 mb-3 row align-items-center justify-content-center"
        >
          <div class="col-auto px-0">
            <a href="tel:88007779347" class="phone-number">8 (800) 777-93-47</a>
            <div class="phone-help">Бесплатно по РФ</div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="about-text">ИНН 4345414049</div>
        <div class="about-text">2015 - 2023 ООО «ПРОМЕТЕЙ ДС»</div>
      </div>
    </div>
    <div class="row align-items-center" v-if="!mobile">
      <div
        class="col-12 col-lg-auto text-center text-lg-end text-lg-start pe-0"
      >
        2015 - 2023 ООО «ПРОМЕТЕЙ ДС» ИНН 4345414049 ОГРН 1154345009037
      </div>
      <div class="col-12 col-lg">
        <div
          class="row justify-content-center justify-content-lg-center align-items-center"
        >
          <div class="col-auto text-end text-xl-center ps-0 pe-xl-4">
            <a
              href="#"
              class="footer-link"
              @click.prevent="$refs.modalPolicy.open()"
              >Политика конфиденциальности</a
            >
          </div>
          <div class="col-auto text-center ps-0">
            <a
              href="#"
              class="footer-link"
              @click.prevent="$refs.orderTracking.open()"
              >Отслеживание заказа</a
            >
          </div>
          <!--          <div class="col-auto">
                      &lt;!&ndash;                        <a
                                      href="https://t.me/domspirt"
                                      target="_blank"
                                      class="footer-link"
                                  >
                                      Канал в
                                      <span class="telegram-color">
                                          <img src="../assets/tg.svg" alt="" /> Telegram
                                      </span>
                                  </a>&ndash;&gt;

                      <a href="https://t.me/domspirt" target="_blank" class="social-link">
                        <span>Канал</span>
                        <img src="./assets/tg.svg" alt="" />
                        <span class="tg">Telegram</span>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCWXYvEq293028RpWhU6lqzA"
                        target="_blank"
                        class="social-link"
                      >
                        <img src="./assets/yt.svg" alt="" />
                        <span class="yt">YouTube</span>
                      </a>
                      <a
                        href="https://vk.com/domspirt"
                        target="_blank"
                        class="social-link"
                      >
                        <img src="./assets/vk.svg" alt="" />
                        <span class="vk">Вконтакте</span>
                      </a>
                    </div>-->
          <!--                    <div class="col-auto ps-0">
                                  <router-link
                                      :to="{ name: 'main', hash: '#tracking' }"
                                      class="footer-link"
                                  >
                                      Отследить заказ
                                  </router-link>
                              </div>-->
          <!--                    <div class="col-auto ps-0">
                                  <a href="#" class="footer-link"
                                      >Для юрлиц оплата с РС</a
                                  >
                              </div>-->
          <!--                    <div class="col-auto ps-0">
                                  <a
                                      href="#"
                                      class="footer-link"
                                      @click.prevent="$refs.modalOferta.open()"
                                      >Не является офертой</a
                                  >
                              </div>-->
        </div>
      </div>
      <div class="col-12 col-lg-auto">
        <div
          class="phone-box mx-0 row align-items-center justify-content-center"
        >
          <div class="col-auto ps-0">
            <img src="./assets/phone1.svg" alt="Телефон" />
          </div>
          <div class="col-auto px-0">
            <a href="tel:88007779347" class="phone-number">8 (800) 777-93-47</a>
            <div class="phone-help">Бесплатно по РФ</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--    <div class="container mt-5">
          <div class="row">
              <div class="col-6 col-md-3">
                  <a href="#" class="footer-link">Комплектация</a>
                  <a href="#" class="footer-link">О компании</a>
              </div>
              <div class="col-6 col-md-3">
                  <a href="#" class="footer-link">Узнать статус заказа</a>
                  <a href="#" class="footer-link">Размеры аппарата</a>
              </div>
              <div class="col-6 col-md-3">
                  <a href="#" class="footer-link">Рецепты напитков</a>
                  <a href="#" class="footer-link">Отзывы</a>
              </div>
              <div class="col-12 col-md-3">
                  <a href="#" class="footer-link">Оплатить онлайн</a>

                  <div class="mt-5">
                      <span class="text-muted">Присоединяйтесь к нам</span>
                      <div>
                          <img
                              v-lazy="{ src: require('../assets/vk.svg') }"
                              class="vk"
                              alt=""
                          />
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-3 mb-3">
              <div class="col-auto">
                  <a href="#" class="footer-link info"
                      >Политика конфиденциальности</a
                  >
              </div>
              <div class="col-auto">
                  <a href="#" class="footer-link info"
                      >Условия дистанционной торговли</a
                  >
              </div>
              <div class="col-auto">
                  <a href="#" class="footer-link info">Незаконное копирование</a>
              </div>
              <div class="col-12 col-md text-end text-muted">ООО "ПРОМЕТЕЙ ДС"</div>
          </div>
      </div>-->

  <Modal ref="modalPolicy">
    <h3>Политика конфиденциальности</h3>
    <div>
      <p>
        Ваша конфиденциальность очень важна для нас. Мы хотим, чтобы Ваша работа
        в Интернет по возможности была максимально приятной и полезной, и Вы
        совершенно спокойно использовали широчайший спектр информации,
        инструментов и возможностей, которые предлагает Интернет.
      </p>
      <p>
        Личная информация, собранная при регистрации (или в любое другое время)
        преимущественно используется для подготовки Продуктов или Услуг в
        соответствии с Вашими потребностями. Ваша информация не будет передана
        или продана третьим сторонам.
      </p>
      <p>
        Какие данные собираются на сайте При добровольной отправки заявки на
        приобретении дистиллятора DOMSPIRT, Вы отправляете свое Имя и Телефон
        через форму регистрации.
      </p>
      <p>
        С какой целью собираются эти данные Имя используется для обращения лично
        к Вам, а ваш Телефон для связи с Вами, для дальнейшего уточнения
        координат, по которым мы вышлем Вам Ваш заказ, для дальнейшего его
        осмотра и приобретения. Также телефон может быть использован для
        sms-информирования о новостях компании и акциях.
      </p>
      <p>
        Ваши имя и Телефон не передаются третьим лицам, ни при каких условиях
        кроме случаев, связанных с исполнением требований законодательства.
      </p>
      <p>
        Для защиты Вашей личной информации мы используем разнообразные
        административные, управленческие и технические меры безопасности. Наша
        Компания придерживается различных международных стандартов контроля,
        направленных на операции с личной информацией, которые включают
        определенные меры контроля по защите информации, собранной в Интернет.
      </p>
      <p>
        Наших сотрудников обучают понимать и выполнять эти меры контроля, они
        ознакомлены с нашим Уведомлением о конфиденциальности, нормами и
        инструкциями.
      </p>
      <p>
        Тем не менее, несмотря на то, что мы стремимся обезопасить Вашу личную
        информацию, Вы тоже должны принимать меры, чтобы защитить ее.
      </p>
      <p>
        Мы настоятельно рекомендуем Вам принимать все возможные меры
        предосторожности во время пребывания в Интернете. Организованные нами
        услуги и веб-сайты предусматривают меры по защите от утечки,
        несанкционированного использования и изменения информации, которую мы
        контролируем. Несмотря на то, что мы делаем все возможное, чтобы
        обеспечить целостность и безопасность своей сети и систем, мы не можем
        гарантировать, что наши меры безопасности предотвратят незаконный доступ
        к этой информации хакеров сторонних организаций.
      </p>
      <p>
        В случае изменения данной политики конфиденциальности Вы сможете
        прочитать об этих изменениях на этой странице или, в особых случаях,
        получить уведомление по Телефону. Для связи с администратором сайта по
        любым вопросам вы можете написать письмо на e-mail: info@prometej.ru.
      </p>
    </div>
  </Modal>

  <Modal ref="modalOferta">
    <div>
      <p>
        1. Настоящие Правила, устанавливающие порядок продажи товаров
        дистанционным способом, регулируют отношения между покупателем и
        продавцом при продаже товаров дистанционным способом при заказе на
        указанном сайте и оказании в связи с такой продажей услуг.
      </p>
      <p>
        2. Основные понятия, используемые в настоящих Правилах, означают
        следующее:<br />
        <b>"покупатель"</b> - гражданин, имеющий намерение заказать или
        приобрести либо заказывающий, приобретающий или использующий товары
        исключительно для личных, семейных, домашних и иных нужд, не связанных с
        осуществлением предпринимательской деятельности;<br />
        <b>"продавец"</b> - организация независимо от ее организационно-правовой
        формы, а также индивидуальный предприниматель, осуществляющие продажу
        товаров дистанционным способом;<br />
        <b>"продажа товаров дистанционным способом"</b> - продажа товаров по
        договору розничной купли-продажи, заключаемому на основании ознакомления
        покупателя с предложенным продавцом описанием товара, содержащимся в
        каталогах, проспектах, буклетах либо представленным на фотоснимках или с
        использованием сетей почтовой связи, сетей электросвязи, в том числе
        информационно-телекоммуникационной сети "Интернет", а также сетей связи
        для трансляции телеканалов и (или) радиоканалов, или иными способами,
        исключающими возможность непосредственного ознакомления покупателя с
        товаром либо образцом товара при заключении такого договора.<br />
        Однако, если Покупатель приобретает Товар с возможностью ознакомления с
        ним перед оплатой (опция «Проверка при получении»), данный факт продажи
        не относится к продаже дистанционным способом, а является фактом обычной
        розничной продажи и регулируется действующим Законодательством.
      </p>
      <p>
        3. При продаже товаров дистанционным способом продавец предлагает
        покупателю услуги по доставке товаров путем их пересылки почтовыми
        отправлениями или перевозки с указанием используемого способа доставки и
        вида транспорта.
      </p>
      <p>
        4. Перечень товаров, продаваемых дистанционным способом, и оказываемых в
        связи с такой продажей услуг определяется продавцом в соответствии с
        указанными материалами на данном сайте либо в других источниках.
      </p>
      <p>
        5. Продавец посредством данного сайта до заключения договора розничной
        купли-продажи (далее - договор) предоставляет покупателю информацию об
        основных потребительских свойствах товара и адресе (месте нахождения)
        продавца, о месте изготовления товара, полном фирменном наименовании
        (наименовании) продавца, о цене и об условиях приобретения товара, о его
        доставке, сроке службы, сроке годности и гарантийном сроке, о порядке
        оплаты товара, а также о сроке, в течение которого действует предложение
        о заключении договора посредством настоящего сайта.
      </p>
      <p>
        6. Продавец обязан довести до сведения покупателя в письменной форме
        следующую информацию (для импортных товаров - на русском языке):
      </p>
      <p>
        а) наименование технического регламента или иное обозначение,
        установленное законодательством Российской Федерации о техническом
        регулировании и свидетельствующее об обязательном подтверждении
        соответствия товара;<br />
        Для дистилляторов бытовых – ТУ 28.29.11–00102561292-2016
      </p>
      <p>
        б) цена в рублях и условия приобретения товара (выполнения работ,
        оказания услуг);<br />
        Предоставлена в транспортной накладной или почтовом идентификаторе, а
        также в материалах данного сайта.
      </p>
      <p>
        в) сведения о гарантийном сроке, если он установлен;<br />
        Предоставлены на данном сайте.
      </p>
      <p>
        г) правила и условия эффективного и безопасного использования
        товаров;<br />
        Предоставлены в руководстве по эксплуатации, ознакомиться можно до
        оплаты при получении, также руководство по эксплуатации можно бесплатно
        скачать на электронную почту.
      </p>
      <p>
        д) сведения о сроке службы или сроке годности товаров, а также сведения
        о необходимых действиях потребителя по истечении указанных сроков и
        возможных последствиях при невыполнении таких действий, если товары по
        истечении указанных сроков представляют опасность для жизни, здоровья и
        имущества покупателя или становятся непригодными для использования по
        назначению;<br />
        Срок службы металлических частей дистиллятора не ограничен.
      </p>
      <p>
        е) место нахождения (адрес), фирменное наименование (наименование)
        изготовителя (продавца), место нахождения (адрес) организации
        (организаций), уполномоченной изготовителем (продавцом) на принятие
        претензий от покупателей и производящей ремонт и техническое
        обслуживание товара, для импортного товара - наименование страны
        происхождения товара;<br />
        Претензии к товару принимаются в письменном виде по Почте РФ, адрес для
        корреспонденции: 610025, Киров, а/я 4.
      </p>
      <p>
        ж) сведения об обязательном подтверждении соответствия товаров (услуг)
        обязательным требованиям, обеспечивающим их безопасность для жизни,
        здоровья покупателя, окружающей среды и предотвращение причинения вреда
        имуществу покупателя в соответствии с законодательством Российской
        Федерации;<br />
        Для дистилляторов бытовых – ТУ 28.29.11–00102561292-2016
      </p>
      <p>
        з) сведения о правилах продажи товаров (выполнения работ, оказания
        услуг);<br />
        Указаны на данном сайте.
      </p>
      <p>л) информация, предусмотренная пунктами 18 и 29 настоящих Правил;</p>
      <p>
        м) информация об энергетической эффективности товаров, в отношении
        которых требование о наличии такой информации определено в соответствии
        с законодательством Российской Федерации об энергосбережении и о
        повышении энергетической эффективности.
      </p>
      <p>
        7. Если приобретаемый покупателем товар был в употреблении или в нем
        устранялся недостаток (недостатки), покупателю должна быть предоставлена
        информация об этом.
      </p>
      <p>
        8. Информация о товаре, включая условия его эксплуатации и правила
        хранения, доводится до покупателя путем размещения на товаре, на
        электронных носителях, прикладываемых к товару, в самом товаре (на
        электронной плате внутри товара в разделе меню), на таре, упаковке,
        ярлыке, этикетке, в технической документации или иным способом,
        установленным законодательством Российской Федерации. Руководство по
        эксплуатации можно бесплатно скачать на электронную почту, а также
        проверить в бумажном виде перед оплатой товара курьеру.
      </p>
      <p>9. Размещенная на сайте информация не является публичной офертой.</p>
      <p>
        10. Продавец обязан информировать покупателя о сроке, в течение которого
        действует предложение о продаже товара дистанционным способом. Сроки
        указываются в разделе «доставка».
      </p>
      <p>
        11. В случае если покупатель передает продавцу сообщение о своем
        намерении приобрести товар, в сообщении должны быть обязательно указаны:
      </p>
      <p>
        а) полное фирменное наименование (наименование) и адрес (место
        нахождения) продавца, фамилия, имя, отчество покупателя или указанного
        им лица (получателя), адрес, по которому следует доставить товар;
      </p>
      <p>
        б) наименование товара, артикул, марка, разновидность, количество
        предметов, входящих в комплект приобретаемого товара, цена товара;
      </p>
      <p>
        в) вид услуги (при предоставлении), время ее исполнения и стоимость;
      </p>
      <p>г) обязательства покупателя.</p>
      <p>
        12. Предложение покупателя о пересылке товаров почтовым отправлением в
        адрес "До востребования" может быть принято только с согласия продавца.
      </p>
      <p>
        13. Продавец должен обеспечивать конфиденциальность персональных данных
        о покупателе в соответствии с законодательством Российской Федерации в
        области персональных данных.
      </p>
      <p>
        14. Организация, осуществляющая продажу товаров дистанционным способом,
        предоставляет покупателю каталоги, буклеты, проспекты, фотографии или
        другие информационные материалы, содержащие полную, достоверную и
        доступную информацию, характеризующую предлагаемый товар.<br />
        Однако, если Покупатель приобретает Товар с возможностью ознакомления с
        ним перед оплатой (опция «Проверка при получении»), данный факт продажи
        не относится к продаже дистанционным способом, а является фактом обычной
        розничной продажи и регулируется действующим Законодательством.
      </p>
      <p>
        15. Обязательства продавца по передаче товара и иные обязательства,
        связанные с передачей товара, возникают с момента получения продавцом
        соответствующего сообщения покупателя о намерении заключить договор.
      </p>
      <p>
        16. Продавец имеет право вносить незначительные конструктивные
        преимущества, не влияющие или незначительно влияющие на внешний вид
        изделия или функциональные характеристики изделия.
      </p>
      <p>
        17. Договор считается заключенным с момента выдачи продавцом покупателю
        кассового или товарного чека либо иного документа, подтверждающего
        оплату товара.
      </p>
      <p>
        18. Покупатель вправе отказаться от товара в любое время до его
        передачи, а после передачи товара - в течение 7 дней.<br />
        Возврат товара надлежащего качества возможен в случае, если сохранены
        его товарный вид, потребительские свойства, а также документ,
        подтверждающий факт и условия покупки указанного товара.<br />
        Покупатель не вправе отказаться от товара надлежащего качества, имеющего
        индивидуально-определенные свойства, если указанный товар может быть
        использован исключительно приобретающим его потребителем.<br />
        При отказе покупателя от товара продавец должен возвратить ему сумму,
        уплаченную покупателем в соответствии с договором, за исключением
        расходов продавца на доставку от покупателя возвращенного товара, не
        позднее чем через 10 дней с даты предъявления покупателем
        соответствующего требования.<br />
        Несмотря на то, что на сайте периодически имеет место акция «бесплатная
        доставка», Продавец несет расходы по доставке товара до клиента.
        Подтверждением расходов Продавца на доставку Товара до Покупателя
        являются счета и акты транспортной компании либо Почты РФ.<br />
        Закон о Защите Прав Потребителей: статья 26.1, часть 4, абзац 5: при
        отказе потребителя от товара продавец должен возвратить ему денежную
        сумму, уплаченную потребителем по договору, за исключением расходов
        продавца на доставку от потребителя возвращенного товара.
      </p>
      <p>
        19. В случае если договор заключен с условием о доставке товара
        покупателю, продавец обязан в установленный договором срок доставить
        товар в место, указанное покупателем, а если место доставки товара
        покупателем не указано, то в место его жительства.<br />
        Для доставки товаров в место, указанное покупателем, продавец может
        использовать услуги третьих лиц (с обязательным информированием об этом
        покупателя).
      </p>
      <p>
        20. Продавец обязан передать товар покупателю в порядке и сроки, которые
        установлены в договоре. Если в договоре срок доставки товара не
        определен и отсутствуют возможности определить этот срок, товар должен
        быть передан продавцом в разумный срок.<br />
        Обязательство, не исполненное в разумный срок, продавец должен выполнить
        в 7-дневный срок со дня предъявления покупателем требования о его
        исполнении. За нарушение продавцом сроков передачи товара покупателю
        продавец несет ответственность в соответствии с гражданским
        законодательством Российской Федерации.
      </p>
      <p>
        21. В случае если доставка товара произведена в установленные договором
        сроки, но товар не был передан покупателю по его вине, последующая
        доставка производится в новые сроки, согласованные с продавцом, после
        повторной оплаты покупателем стоимости услуг по доставке товара.
      </p>
      <p>
        22. Продавец обязан передать покупателю товар, качество которого
        соответствует договору и информации, представленной покупателю при
        заключении договора, а также информации, доведенной до его сведения при
        передаче товара.<br />
        При отсутствии в договоре условий о качестве товара продавец обязан
        передать покупателю товар, пригодный для целей, для которых товар такого
        рода обычно используется. Если продавец при заключении договора был
        поставлен покупателем в известность о конкретных целях приобретения
        товара, продавец обязан передать покупателю товар, пригодный для
        использования в соответствии с этими целями. Если иное не предусмотрено,
        продавец обязан одновременно с передачей товара передать покупателю
        соответствующие принадлежности, а также относящиеся к товару документы
        (сертификат качества, инструкцию по эксплуатации и т.п.).
      </p>
      <p>
        23. Доставленный товар передается покупателю по месту его жительства или
        иному указанному им адресу, а при отсутствии покупателя - любому лицу,
        предъявившему квитанцию или иной документ, подтверждающий заключение
        договора или оформление доставки товара.
      </p>
      <strong id="retrun_policy">Условия возврата товара</strong>
      <p>
        24. В случае если покупателю передается товар с нарушением условий
        договора, касающихся количества, ассортимента, качества, комплектности,
        тары и (или) упаковки товара, покупатель может не позднее 10 дней после
        получения товара известить продавца об этих нарушениях.<br />
        Покупатель извещает Продавца о нарушении посредством телефонного звонка,
        сообщения на электронную почту, письмом на Почту РФ по адресу 610025,
        а/я 4.<br />
        Если при получении обнаружены недостатки товара, могущие возникнуть при
        транспортировке, в присутствии курьера, либо работника Почты РФ
        составляется акт о несоответствии (браке). При отсутствии данного акта
        претензии Покупателя рассматриваются индивидуально.<br />
        Покупатель также вправе предъявить требования к продавцу в отношении
        недостатков товара, если они обнаружены в течение гарантийного срока или
        срока годности.
      </p>
      <p>
        25. Покупатель, которому продан товар ненадлежащего качества, если это
        не было оговорено продавцом, вправе по своему выбору потребовать:
      </p>
      <p>
        а) безвозмездного устранения недостатков товара или возмещения расходов
        на их исправление покупателем или третьим лицом;
      </p>
      <p>б) соразмерного уменьшения покупной цены;</p>
      <p>
        в) замены на товар аналогичной марки (модели, артикула) или на такой же
        товар другой марки (модели, артикула) с соответствующим перерасчетом
        покупной цены. При этом в отношении технически сложных и дорогостоящих
        товаров эти требования покупателя подлежат удовлетворению в случае
        обнаружения существенных недостатков.
      </p>
      <p>
        26. Покупатель вместо предъявления требований, указанных в пункте 25
        настоящих Правил, вправе отказаться от исполнения договора и потребовать
        возврата уплаченной за товар суммы. По требованию продавца и за его счет
        покупатель должен возвратить товар с недостатками. Возврат денежных
        средств осуществляется способами, описанными в п. 32. Для возврата
        денежных средств Покупателем оформляется претензия в письменном виде
        согласно п. 30 и направляется в адрес продавца по адресу 610025, Киров,
        а/я 4.
      </p>
      <p>
        27. При отказе продавца передать товар покупатель вправе отказаться от
        исполнения договора.
      </p>
      <p>
        28. При возврате товара ненадлежащего качества отсутствие у покупателя
        документа, подтверждающего факт и условия покупки товара, не лишает его
        возможности ссылаться на другие доказательства приобретения товара у
        продавца.
      </p>
      <p>
        29. Возврат товара осуществляется либо на почтовый адрес Продавца
        (610025, г. Киров, а/я 4) либо на указанный продавцом терминал
        транспортной компании с сохранением упаковки и внешнего вида товара.
        Продавец переводит денежные средства в безналичной форме на счет (карту)
        покупателя в течение 10 рабочих дней с момента получения возвращенного
        Товара. Если Товар возвращен с недостатками, не указанными Покупателем в
        претензии, Продавец имеет право провести внутреннее расследование,
        направленное на выяснение.
      </p>
      <p>
        30. При возврате покупателем товара надлежащего качества Покупателем
        составляются письменная претензия или акт о возврате товара, в которых
        указываются:
      </p>

      <p>а) фамилия, имя, отчество покупателя с живой подписью;</p>
      <p>б) наименование товара;</p>
      <p>в) даты заключения договора и передачи товара;</p>
      <p>г) сумма, подлежащая возврату;</p>
      <p>
        Письменная претензия направляется Почтой РФ по адресу 610025, Киров, а/я
        4.
      </p>
      <p>
        31. В случае если возврат суммы, уплаченной покупателем в соответствии с
        договором, осуществляется неодновременно с возвратом товара покупателем,
        возврат указанной суммы осуществляется продавцом с согласия покупателя
        одним из следующих способов:
      </p>
      <p>а) наличными денежными средствами по месту нахождения продавца;</p>
      <p>б) почтовым переводом;</p>
      <p>
        в) путем перечисления соответствующей суммы на банковский или иной счет
        покупателя, указанный покупателем.
      </p>
      <p>
        32. Расходы на осуществление возврата суммы, уплаченной покупателем в
        соответствии с договором, несет продавец.
      </p>
    </div>
  </Modal>

  <Modal ref="modalWhisky">
    <div class="mb-4 whisky-text">
      Мировые производители дистиллятов: виски и коньяка, такие как Jameson,
      Hennessy, Baccardi, Jack Daniels - используют медь в конструировании
      колонн и нержавеющую сталь в конструкции кубов и теплообменников. DomSpirt
      старается соответствовать.
    </div>
    <div class="row">
      <div class="col-12 col-md-4 mb-3" v-for="n in 6" :key="'whi_' + n">
        <img
          v-lazy="{
            src: require('./assets/WhiskyImages/' + n + '.png'),
          }"
          class="w-100"
          alt=""
        />
      </div>
    </div>
  </Modal>

  <Modal ref="modalSpirt">
    <h3>Исследование</h3>
    <pdf
      class="mb-2"
      src="static/file.pdf"
      :page="n"
      v-for="n in 7"
      :key="'pdf_' + n"
    >
      <template slot="loading">Загрузка...</template>
    </pdf>
  </Modal>

  <Modal ref="orderTracking">
    <div class="container">
      <h3>Отслеживание заказа</h3>
      <delivery-tracking-modal />
    </div>
  </Modal>
</template>

<script>
import pdf from "pdfvuer";
import Modal from "@/ui/Modal";
import DeliveryTrackingModal from "@/pages/Deliveries/components/DeliveryTrackingModal";

export default {
  name: "AppFooter",
  components: { DeliveryTrackingModal, Modal, pdf },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    if (window.innerWidth < 768) this.mobile = true;
    this.$root.proxy.AppFooter = this;
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  font-size: 12px;
  color: #d6d6d6;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 10px;
  }

  .footer-link {
    color: #008fff;
    border-bottom: 1px solid rgba(0, 143, 255, 0.55);

    &:hover {
      text-decoration: none;
      border-color: #008fff;
    }

    @media (max-width: 1200px) {
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .telegram-color {
    color: #0088cc;
    font-weight: 700;

    img {
      width: 22px;
      margin: -5px 0px -5px 4px;
    }
  }
}

.footer-mobile {
  font-size: 10px;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    .footer-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .col-auto {
    border-right: 1px solid #979797;
    padding: 0 5px;
    margin-right: 5px;

    &:last-child {
      border-right: none;
      margin-right: 0;
    }
  }

  .about-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #d6d6d6;
  }
}

.footer-menu-mobile {
  //border-top: 1px solid #06db71;
  padding-top: 15px;
  text-align: center;
  //margin-top: 40px;
  .menu-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #cacaca;
    margin-bottom: 17px;
    display: inline-block;
  }
}

.social-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  @media (min-width: 991px) {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  img {
    margin: 0 6px;
  }

  text-decoration: none;
  margin-bottom: 17px;

  span {
    color: #ffffff;
    border-bottom: 1px solid currentColor;
    opacity: 0.8;
  }

  .tg {
    color: #0098da;
  }

  .yt {
    color: #ea412c;
  }

  .vk {
    color: #0077ff;
  }

  &:hover {
    span {
      opacity: 1;
      //border-bottom: 1px solid transparent;
    }
  }

  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 14px;
    display: block;
    span {
      color: #cacaca;
      opacity: 1;
    }
  }
}

//.vk {
//    filter: invert(100%) sepia(0%) saturate(7485%) hue-rotate(246deg)
//        brightness(104%) contrast(104%);
//    transition: 0.2s;
//    cursor: pointer;
//    &:hover {
//        filter: invert(46%) sepia(14%) saturate(1580%) hue-rotate(171deg)
//            brightness(90%) contrast(89%);
//    }
//}
.whisky-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  color: #e0e0e0;
}

.phone-box {
  .phone-number {
    color: #c9c9c9;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .phone-help {
    color: #6c757d;
    font-size: 14px;
    text-align: center;
  }
}
</style>
