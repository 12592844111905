<template>
  <transition name="fade">
    <app-loading v-if="loading" />
  </transition>
  <app-menu />
  <router-view />
  <app-footer />
  <!--    <debug-monitor />-->
</template>

<script>
import AppMenu from "@/components/AppMenu";
import AppLoading from "@/components/AppLoading";
import AppFooter from "@/components/AppFooter";
// import DebugMonitor from "@/ui/DebugMonitor";
import { LOAD_USER_HISTORY } from "@/store/modules/user";

export default {
  components: {
    // DebugMonitor,
    AppMenu,
    AppLoading,
    AppFooter,
  },
  data() {
    return {
      loading: true,
      proxy: {},
    };
  },
  created() {
    this.$store.dispatch(LOAD_USER_HISTORY);
  },
  methods: {
    requireCss(url) {
      let cssMain = document.createElement("link");
      cssMain.href = url;
      cssMain.rel = "stylesheet";
      cssMain.type = "text/css";
      document.getElementsByTagName("head")[0].appendChild(cssMain);
    },
  },
  mounted() {
    document.body.classList.add("dm-modal-open");
    // let name = params.get("name");
    setTimeout(() => {
      this.$nextTick(() => {
        this.loading = false;
        this.requireCss(
          "https://cdn.jsdelivr.net/npm/@fancyapps/ui/dist/fancybox.css"
        );
        this.requireCss(
          "https://cdn.jsdelivr.net/npm/@splidejs/splide@4.0.2/dist/css/splide.min.css"
        );
        document.body.classList.remove("dm-modal-open");
      });
    }, 1200);
  },
};
</script>
