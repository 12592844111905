import api from "@/api";

// import webp from "@/functions/webp";
import kolonna from "@/store/modules/items/kolonna";
// import uzelOtbora from "@/store/modules/items/uzel-otbora";
import uvelichitel from "@/store/modules/items/uvelichitel";
import falshDno from "@/store/modules/items/falsh-dno";
import zapasnoeSteklo from "@/store/modules/items/zapasnoe-steklo";
import zapasProkladok from "@/store/modules/items/zapas-prokladok";
// import popugai from "@/store/modules/items/popugai";
import kub from "@/store/modules/items/kub";
import ten35 from "@/store/modules/items/ten-35";
// import ten30 from "@/store/modules/items/ten-30";
import regulator from "@/store/modules/items/regulator";
// import automatic from "@/store/modules/items/automatic";
import dopCarga12 from "@/store/modules/items/dop-carga12";
// import quickReleases from "@/store/modules/items/quick-releases";
import perehod from "@/store/modules/items/perehod";
// import dopCarga9 from "@/store/modules/items/dop-carga/index";

const getters = {
  deliveryPoint: (state) => state.delivery_point,
  date: (state) => state.date,
  dateUp1: (state) => state.dateUp1,
  modal: (state) => state.modal,
  cart: (state) => {
    return state.items
      .filter((v) => v.active)
      .map((v) => {
        return { id: v.id, count: v.count };
      });
    // let cart = state.items
    //     .filter((v) => v.active)
    //     .map((v) => {
    //         return { id: v.id, count: v.count }; //
    //     });
    // let cartForSent = cart; // id count
    // let cartIds = cart.map((v) => {
    //     return v.id;
    // });
    // let usedKit = false;
    // state.kits.forEach((value) => {
    //     if (!usedKit) {
    //         let activeKit = value.items.every((r) => cartIds.includes(r));
    //         if (activeKit) {
    //             usedKit = true;
    //
    //             cartForSent = cartForSent.filter(
    //                 (v) => !value.items.includes(v.id)
    //             );
    //
    //             cartForSent.push({
    //                 id: value.id,
    //                 count: 1,
    //             });
    //
    //             return false;
    //         }
    //     }
    // });
    //
    // return cartForSent;
  },
  items: (state) => state.items,
  gifts: (state) => state.gifts,
  kits: (state) => state.kits,
  promo: (state) => state.promo,
  // orderPrice: (state) => {
  //     return state.items
  //         .filter((v) => v.active)
  //         .reduce((pv, v) => {
  //             return v.gift ? pv : pv + v.price * v.count;
  //         }, 0);
  // },
  orderId: (state) => state.order_id,
};

let date = new Date();
date.setDate(date.getDate() + 1);

let dateUp = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}.${
  date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
}`;

const state = {
  date: `${
    new Date().getDate() > 9 ? new Date().getDate() : `0${new Date().getDate()}`
  }.${
    new Date().getMonth() > 8
      ? new Date().getMonth() + 1
      : `0${new Date().getMonth() + 1}`
  }`,
  dateUp1: dateUp,
  modal: false,
  cart: [],
  promo: {
    id: null,
    code: null,
    discount: 0,
    gift_items: [],
    gift_ids: [],
    required_items: [],
    any: null,
  },
  gifts: [
    // {
    //   id: 2333,
    //   promo: "UOGIFT",
    //   conditions: [
    //     {
    //       type: "item",
    //       id: 2400,
    //     },
    //     {
    //       type: "item",
    //       id: 2240,
    //     },
    //   ],
    // },
    // {
    //   id: 2333,
    //   promo: "UOGIFT",
    //   conditions: [
    //     {
    //       type: "item",
    //       id: 2401,
    //     },
    //     {
    //       type: "item",
    //       id: 2240,
    //     },
    //   ],
    // },
  ],
  kits: [
    // {
    //   name: "Аппарат DomSpirt 2 - 30 нерж. тар.",
    //   id: 2546,
    //   items: [2265, 2552, 2550],
    //   discount: 2990,
    // },
    // {
    //   name: "Аппарат DomSpirt 2 - 18 нерж. тар.",
    //   id: 2545,
    //   items: [2552, 2550],
    //   discount: 2990,
    // },
    // {
    //   name: "Аппарат DomSpirt 2 - 6 нерж. тар.",
    //   id: 2544,
    //   items: [2552, 2550],
    //   discount: 6990,
    // },
    // {
    //   name: "Аппарат DomSpirt 2 - 6 мед. тар. + 24 нерж.",
    //   id: 2549,
    //   items: [2265, 2555, 2550],
    //   discount: 2990,
    // },
    {
      name: "Аппарат DomSpirt 2 - 6 мед. тар. + 12 нерж.",
      id: 2548,
      items: [2554, 2550, 2265],
      discount: 4990,
    },
    {
      name: "Аппарат DomSpirt 2 - 6 мед. тар.",
      id: 2547,
      items: [2554, 2550],
      discount: 4990,
    },
    // {
    //   name: "Колонна DomSpirt 2 - 30 нерж. тар.",
    //   id: 2553,
    //   items: [2265, 2552],
    //   discount: 0,
    // },
    // {
    //   name: "Колонна DomSpirt 2 - 18 нерж. тар.",
    //   id: 2552,
    //   items: [2552],
    //   discount: 3990,
    // },
    // {
    //   name: "Колонна DomSpirt 2 - 6 мед. тар. + 24 нерж.",
    //   id: 2556,
    //   items: [2265, 2555],
    //   discount: 0,
    // },
    // {
    //   name: "Колонна DomSpirt 2 - 6 мед. тар. + 12 нерж.",
    //   id: 2555,
    //   items: [2554, 2265],
    //   discount: 0,
    // },
    // {
    //   name: "Аппарат нерж",
    //   id: 2367,
    //   items: [2552, 2550],
    //   discount: 3990,
    // },
    // {
    //     name: "Колонна мед",
    //     id: 2401,
    //     items: [2400, 2401],
    //     discount: 1990,
    // },
  ],
  items: [
    kolonna,
    // uzelOtbora,
    // dopCarga9,
    dopCarga12,
    uvelichitel,
    falshDno,
    zapasnoeSteklo,
    zapasProkladok,
    // quickReleases,
    // popugai,
    kub,
    ten35,
    regulator,
    // automatic,
    perehod,
    /*{
        id: 2279,
        display: false,
        subitem: 2400,
        metrikaGoal: "carga_med",
        name: {
          full: "Доп. царга 9 тарелок медь",
          short: "Царга 9<br>тарелок медь",
        },
        html:
          `<div class="row">
                  <div class="col-12 col-md-6">
                      <img src="` +
          require("../../pages/Main/assets/modal/m_kolm.png") +
          webp() +
          `" alt="" class="im-image">
                  </div>
                  <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                      <div class="flex-fill d-flex">
                          <div>
                              <div class="im-title">Доп. колонна 9 медных тарелок</div>
                              <div class="im-subtitle">Крепость спирта 96,9% об. на 2 л/час и очистка от сернистых соединений</div>
                              <div class="im-text">
                                  <p>Пищевой спирт по ГОСТ 5962-2013 имеет макс. крепость 96,3%об. Колонна DomSpirt на 18 тарелок в штатной комплектации обеспечивает такую крепость при перегонке 2 л/час, что в 2 раза выше 2" колонн. Но для крепости ещё выше, до 96,9% об., количество ступеней перегонки можно увеличить.</p>
                                  <p>Доп. царга-колонна DomSpirt увеличивает количество ступеней перегонки на 9 шт и увеличивает укрепление напитка. Модульная конструкция позволяет установить от 1 до 9 тарелок (например, 3 шт через два стекла). Благодаря тому, что в конструкции использованы стандартные прокладки и стёкла от диоптра 3” высотой 40 мм, вся колонна - это универсальный набор стандартных взаимозаменяемых частей.</p>
                                  <p>Медь (9 тарелок) улучшает аромат алкоголя (даже обычного сахарного самогона) за счет связывания сернистых соединений. Рекомендуется ставить не более 6-7 медных тарелок на 2 перегонку, чтобы не «отсечь» вкус ингредиентов. На 1 перегонку рекомендуется ставить все 9. В комплекте: колонна 3” (76 мм), кламп 2” и прокладка 2”, доп. креплений покупать не нужно.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row align-items-center im-delivery-container">
                          <div class="col-auto">
                              <img src="` +
          require("../../pages/Main/assets/car.svg") +
          `" alt="" class="im-delivery-icon">
                          </div>
                          <div class="col col-md-8 im-delivery-text">
                              Доставка при заказе с аппаратом DOMSPIRT бесплатная.
                          </div>
                      </div>
                  </div>
              </div>`,
        price: 10020,
        layers: {
          "*": [
            {
              layer: 52,
              name: "tsarga_plus_med",
              url:
                require("../../pages/Main/assets/order/tsarga_plus_med.png") +
                webp(),
            },
          ],
        },
        count: 1,
        active: false,
      },*/
    /*{
        id: 2401,
        display: false,
        stopBuyOne: true,
        hidePlus: true,
        is_radio: true,
        subitem: 2400,
        metrikaGoal: "med_tar",
        name: {
          full: "9 медных тарелок (замена нерж.)",
          short: "9 медн.<br>тарелок (замена)",
        },
        html:
          `<div class="row">
                  <div class="col-12 col-md-6">
                      <img src="` +
          require("../../pages/Main/assets/modal/m_med.png") +
          webp() +
          `" alt="" class="im-image">
                  </div>
                  <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                      <div class="flex-fill d-flex">
                          <div>
                              <div class="im-title">Заменить 9 тарелок на медные</div>
                              <div class="im-subtitle">Для перегонки зерновых и фруктово-ягодных браг</div>
                              <div class="im-text">
                                  <p>Можно заменить 9 нерж. тарелок в колонне на 9, выполненных из меди М1. Медь улучшает аромат алкоголя за счет связывания сернистых соединений. Соединения серы характерны для зерновой браги (виски) и плодово-ягодной браги (бренди). Медь рекомендуется именно для перегонки ароматных спиртов. </p>
                                  <p>Адсорбцию (очистку от) сернистых соединений в парах спирта с помощью меди проводят как на первой, так и на второй перегонке. Именно для этого количество тарелок достаточно большое - 9 шт, чтобы контакт спиртовых паров с медью был интенсивным. </p>
                                  <p>Если Вы начинающий винокур и хотите только начать эксперименты (начинать всегда рекомендуется с сахарной браги, как с самой простой), либо предпочитаете перегонку только сахарной браги, медные тарелки можно не приобретать либо заказать позже, когда опыты со спиртом из сахара закончатся и захочется чего-то более изысканного. </p>
                              </div>
                          </div>
                      </div>
                      <div class="row align-items-center im-delivery-container">
                          <div class="col-auto">
                              <img src="` +
          require("../../pages/Main/assets/car.svg") +
          `" alt="" class="im-delivery-icon">
                          </div>
                          <div class="col col-md-8 im-delivery-text">
                              Доставка при заказе с аппаратом DOMSPIRT бесплатная.
                          </div>
                      </div>
                  </div>
              </div>`,
        price: 3990,
        layers: {
          0: [
            {
              layer: 51,
              name: "tsarga_med",
              hide: ["tsarga_nerj"],
              url:
                require("../../pages/Main/assets/order/tsarga_med.png") + webp(),
            },
            {
              layer: 52,
              name: "up",
              url: require("../../pages/Main/assets/order/up.png") + webp(),
            },
          ],
          1: [
            {
              layer: 52,
              name: "tsarga_med_size",
              hide: ["tsarga_nerj_size"],
              url:
                require("../../pages/Main/assets/order/tsarga_med_size.png") +
                webp(),
            },
            {
              layer: 53,
              name: "up_size",
              url: require("../../pages/Main/assets/order/up_size.png") + webp(),
            },
          ],
        },
        count: 1,
        active: false,
      },*/
    //        {
    //            id: 2230,
    //            display: true,
    //            metrikaGoal: "temp",
    //            name: "Блок темп. контроля",
    //            short: "Блок темп. контроля",
    //            html:
    //                `<div class="row">
    //                <div class="col-12 col-md-6">
    //                    <img src="` +
    //                require("../../pages/Main/assets/modal/m_blok_temp.png") +
    //                webp() +
    //                `" alt="" class="im-image">
    //                </div>
    //                <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
    //                    <div class="flex-fill d-flex">
    //                        <div>
    //                            <div class="im-title">Блок темп. контроля</div>
    //                            <div class="im-subtitle">Стандартное стекло от диоптра DN3” дюйма</div>
    //                            <div class="im-text">
    //                                <p>Домашний спиртзавод DomSpirt имеет неограниченный срок эксплуатации, даже без проведения какого-либо ТО или ухода. Какие-то расходные материалы или замены прокладок со временем не требуются. Но если есть сомнения, можно приобрести запасное стекло. В комплекте с DomSpirt есть 18 таких стекол, причем даже если вывести из строя половину из них, колонну можно будет собрать и она будет работать, просто крепость продукта будет ниже.</p>
    // <p>Стандартное кварцевое стекло от диоптра DN 3” дюйма может быть использовано как в тарельчатой колонне, так и в диоптре 3” дюйма стороннего производителя. Толстые стенки 5 мм и небольшая высота делают стекло прочным и надёжным. Высота 40 мм, внутренний диаметр 76 мм, толщина 5 мм. </p>
    //                            </div>
    //                        </div>
    //                    </div>
    //                    <div class="row align-items-center im-delivery-container">
    //                        <div class="col-auto">
    //                            <img src="` +
    //                require("../../pages/Main/assets/car.svg") +
    //                `" alt="" class="im-delivery-icon">
    //                        </div>
    //                        <div class="col col-md-8 im-delivery-text">
    //                            Доставка при заказе с аппаратом DOMSPIRT бесплатная.
    //                        </div>
    //                    </div>
    //                </div>
    //            </div>`,
    //            price: 990,
    //            layers: {
    //                0: [
    //                    {
    //                        layer: 52,
    //                        name: "temp",
    //                        url:
    //                            require("../../pages/Main/assets/order/temp.png") +
    //                            webp(),
    //                    },
    //                ],
    //                1: [
    //                    {
    //                        layer: 52,
    //                        name: "temp",
    //                        url:
    //                            require("../../pages/Main/assets/order/temp_size.png") +
    //                            webp(),
    //                    },
    //                ],
    //            },
    //            count: 1,
    //            active: false,
    //        },
    /*{
            id: 2151,
            display: true,
            metrikaGoal: "spirtomer",
            name: "3 спиртометра ГОСТ",
            short: "3 спиртометра",
            html:
                `<div class="row">
                <div class="col-12 col-md-6">
                    <img src="` +
                require("../../pages/Main/assets/modal/m_spirt3.png") +
                webp() +
                `" alt="" class="im-image">
                </div>
                <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                    <div class="flex-fill d-flex">
                        <div>
                            <div class="im-title">3 спиртомера АСП-3</div>
                            <div class="im-subtitle">Высокоточный контроль по ГОСТ 18481-81</div>
                            <div class="im-text">
                                <p>В базовой комплектации DomSpirt есть 1 спиртометр, характеристик которого достаточно, чтобы измерить общую крепость напитка, довести продукт до питейной 40%об. крепости или выше (как, например, 50-градусный коньяк или 70-ти абсент). Но профессионалам часто требуется высокоточный контроль с точностью до десятых об.</p>
                                <p>Набор лабораторных спиртометров-ареометров АСП-3 ГОСТ 18481-81 позволяет измерять крепость спирта с точностью до десятых об. В комплекте 3 шт спиртометра: 0-40% об. - идеален для разведения сырца и отслеживания конца перегонки, 40-70% об. - для доведения напитка до питейной крепости и 70-100% - для контроля дробной перегонки. Каждый спиртометр упакован в индивидуальный тубус.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center im-delivery-container">
                        <div class="col-auto">
                            <img src="` +
                require("../../pages/Main/assets/car.svg") +
                `" alt="" class="im-delivery-icon">
                        </div>
                        <div class="col col-md-8 im-delivery-text">
                            Доставка при заказе с аппаратом DOMSPIRT бесплатная.
                        </div>
                    </div>
                </div>
            </div>`,
            price: 990,
            layers: {},
            count: 1,
            active: false,
        },*/
    /*{
            id: 9999,
            display: true,
            subitem: 2400,
            metrikaGoal: "carga_sep",
            name: "Разделитель царги",
            short: "Разделитель царги",
            html:
                `<div class="row">
                <div class="col-12 col-md-6">
                    <img src="` +
                require("../../pages/Main/assets/modal/m_kolm.png") +
                webp() +
                `" alt="" class="im-image">
                </div>
                <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
                    <div class="flex-fill d-flex">
                        <div>
                            <div class="im-title">Доп. колонна 9 тарелок: 8 медных, 1 нерж</div>
                            <div class="im-subtitle">Крепость спирта 96,9% об. на 2 л/час и очистка от сернистых соединений</div>
                            <div class="im-text">
                                <p>Пищевой спирт по ГОСТ 5962-2013 имеет макс. крепость 96,3%об. Колонна DomSpirt на 18 тарелок в штатной комплектации обеспечивает такую крепость при перегонке 2 л/час, что в 2 раза выше 2 дюймовых колонн. Однако для крепости ещё выше, до 96,9% об., количество ступеней перегонки можно увеличить.</p>
                                <p>Колонна DomSpirt c 9 ситчатыми тарелками обеспечивает значительное укрепление напитка и может быть установлена на любой аппарат для увеличения крепости. Модульная конструкция позволяет установить от 1 до 9 тарелок в колонне (например, 3 шт через два стекла). Благодаря тому, что в конструкции использованы стандартные прокладки и стёкла от диоптра 3” высотой 40 мм, вся колонна - это универсальный набор стандартных взаимозаменяемых частей.</p>
                                <p>Медь (8 медных тарелок и 1 нержавеющая опорная) улучшает аромат алкоголя (даже обычного сахарного самогона) за счет связывания сернистых соединений. Рекомендуется ставить не более 6-7 медных тарелок, чтобы не «отсечь» вкус ингредиентов. В комплекте: колонна 3” (76 мм), кламп 2” и прокладка 2”, доп. креплений покупать не нужно.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center im-delivery-container">
                        <div class="col-auto">
                            <img src="` +
                require("../../pages/Main/assets/car.svg") +
                `" alt="" class="im-delivery-icon">
                        </div>
                        <div class="col col-md-8 im-delivery-text">
                            Доставка при заказе с аппаратом DOMSPIRT бесплатная.
                        </div>
                    </div>
                </div>
            </div>`,
            price: 990,
            layers: {},
            count: 1,
            active: false,
        },*/
  ],
  order_id: null,
  delivery_point: null,
};

export const EDIT_MODAL = "editModal";
export const SET_ORDER_ID = "setOrderId";
export const SET_PROMO_CODE = "setPromoCode";
export const SET_PROMO_DISCOUNT = "setPromoDiscount";
export const SET_PROMO_ITEMS = "setPromoItems";
export const SET_PROMO_DATA = "setPromoData";
export const ADD_GIFTS_DATA = "addGiftsData";
export const SET_DELIVERY_POINT = "setDeliveryPoint";

const mutations = {
  [EDIT_MODAL](state, payload) {
    state.modal = payload;
  },
  [SET_ORDER_ID](state, payload) {
    state.order_id = payload;
  },
  [SET_PROMO_CODE](state, payload) {
    state.promo.code = payload;
  },
  [SET_PROMO_DISCOUNT](state, payload) {
    state.promo.discount = payload;
  },
  [SET_PROMO_ITEMS](state, payload) {
    state.promo.items = payload;
  },
  [SET_DELIVERY_POINT](state, payload) {
    state.delivery_point = payload;
  },
  [SET_PROMO_DATA](state, payload) {
    state.promo = payload;
  },
  [ADD_GIFTS_DATA](state, payload) {
    state.gifts.push(payload);
  },
};

export const TOGGLE_ITEM = "toggleItem";
export const CREATE_ORDER = "createOrder";
export const CREATE_REVIEW = "createReview";
export const CREATE_QUESTION = "createQuestion";
export const CREATE_ORDER_ONE = "createOrderOne";
export const GET_BOOK = "getBook";
export const SET_ACTIVE = "setActive";
export const SET_VARIANT = "setVariant";
export const SET_DEACTIVE = "setDeactive";
// export const CHECK_GIFTS = "checkGifts";
export const SET_PROMO = "setPromo";
export const ACTIVATE_GIFTS = "activateGifts";
export const GET_COORDINATES = "getCoordinates";

const actions = {
  [TOGGLE_ITEM](context, payload) {
    return new Promise((resolve) => {
      if (payload.radio) {
        let radioItems = context.getters.items.filter(
          (v) => v.radio === payload.radio
        );
        radioItems.forEach((v) => {
          context.dispatch(SET_DEACTIVE, v);
        });
      }
      payload.active = !payload.active;
      if (payload.subitem) {
        let item = context.getters.items.find((v) => v.id === payload.subitem);
        if (item) {
          context.dispatch(SET_ACTIVE, item);
        }
      } else {
        if (!payload.active) {
          let items = context.getters.items.filter(
            (v) => v.subitem === payload.id
          );
          items.forEach((v) => {
            context.dispatch(SET_DEACTIVE, v);
          });
        } else {
          let subItems = context.getters.items.filter(
            (v) => v.subitem === payload.id && v.radio
          );
          let activatedRadio = [];
          subItems.forEach((v) => {
            if (!activatedRadio.includes(v.radio)) {
              activatedRadio.push(v.radio);
              context.dispatch(SET_ACTIVE, v);
            }
          });
        }
      }
      if (payload.id === 2558) {
        if (payload.active) payload.count = 1;
        if (!payload.active) payload.count = 0;
      }
      resolve();
    });
  },
  [SET_ACTIVE](context, payload) {
    return new Promise((resolve) => {
      payload.active = true;
      if (payload.id === 2558) {
        if (payload.active) payload.count = 1;
        if (!payload.active) payload.count = 0;
      }
      let subItems = context.getters.items.filter(
        (v) => v.subitem === payload.id && v.radio
      );
      let activatedRadio = [];
      subItems.forEach((v) => {
        if (!activatedRadio.includes(v.radio)) {
          activatedRadio.push(v.radio);
          context.dispatch(SET_ACTIVE, v);
        }
      });
      resolve();
    });
  },
  [SET_VARIANT](context, payload) {
    return new Promise((resolve) => {
      let item = context.getters.items.find((v) => payload.item.id === v.id);
      item.variant_id = payload.variant.id;
      resolve();
    });
  },
  [SET_DEACTIVE](context, payload) {
    return new Promise((resolve) => {
      payload.active = false;
      if (payload.id === 2558) {
        if (payload.active) payload.count = 1;
        if (!payload.active) payload.count = 0;
      }
      resolve();
    });
  },
  [CREATE_ORDER](context, payload) {
    return new Promise((resolve, reject) => {
      if (context.getters.orderId === null) {
        let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
        if (phone.length >= 11) {
          let usedKit = false;
          let promo = null;
          // let cartForSent = context.getters.cart; // id count

          let cartForSent = context.getters.cart.map((v) => {
            let res = null;
            let item = context.getters.items.find((find) => find.id === v.id);
            if (item.variant_id) {
              res = item.variants.find(
                (variant) => variant.id == item.variant_id
              );
            } else {
              res = item;
            }
            return { ...v, id: res.id };
          });
          // let cartIds = context.getters.cart.map((v) => {
          //     return v.id;
          // });

          // if (
          //   cartForSent.map((v) => v.id).includes(2401) &&
          //   cartForSent.map((v) => v.id).includes(2149)
          // ) {
          //   cartForSent = cartForSent.filter(
          //     (v) => v.id !== 2401 && v.id !== 2149
          //   );
          //   cartForSent.push({
          //     id: 2279,
          //     count: 1,
          //   });
          // }

          let cartIds = cartForSent.map((v) => {
            return v.id;
          });

          context.getters.gifts.forEach((value) => {
            let requiredItems = value.conditions
              .filter((f) => f.type === "item")
              .map((i) => {
                return i.id;
              });
            let activeGift = requiredItems.every((r) => cartIds.includes(r));
            if (activeGift && cartIds.includes(value.id)) {
              promo = value.promo;
              cartForSent = cartForSent.filter((v) => value.id != v.id);
            }
          });

          context.getters.kits.forEach((value) => {
            if (!usedKit) {
              let activeKit = value.items.every((r) => cartIds.includes(r));
              if (activeKit) {
                usedKit = true;

                cartForSent = cartForSent.filter(
                  (v) => !value.items.includes(v.id)
                );

                cartForSent.push({
                  id: value.id,
                  count: 1,
                });

                return false;
              }
            }
          });

          api
            .post("/order.create", {
              user: { ...context.getters.user, phone },
              cart: cartForSent,
              promo: payload.promo_active ? context.getters.promo.code : promo,
              clientId: payload.clientId,
              site_version: 2,
              delivery_point: context.getters.deliveryPoint,
              ip: context.getters.user.ip,
            })
            .then((r) => {
              context.commit(SET_ORDER_ID, r.data.id);
              resolve(r.data);
            })
            .catch((r) => {
              reject(r);
            });
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(["phone"]);
        }
      } else {
        resolve({ id: context.getters.orderId });
      }
    });
  },
  [CREATE_ORDER_ONE](context, { itemId, clientId }) {
    return new Promise((resolve, reject) => {
      let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
      if (phone.length >= 11) {
        api
          .post("/order.create", {
            user: { ...context.getters.user, phone },
            cart: [{ count: 1, id: itemId }],
            site_version: 2,
            clientId: clientId,
            delivery_point: context.getters.deliveryPoint,
            ip: context.getters.user.ip,
          })
          .then((r) => {
            // context.commit(SET_ORDER_ID, r.data.id);
            resolve(r.data);
          })
          .catch((r) => {
            reject(r);
          });
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(["phone"]);
      }
    });
  },
  [CREATE_REVIEW](context, reviewText) {
    return new Promise((resolve, reject) => {
      let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
      let errors = [];
      if (reviewText.length < 3) errors.push("text");
      if (phone.length < 11) errors.push("phone");

      if (errors.length === 0) {
        api
          .post("/review.create", {
            user: { ...context.getters.user, phone },
            cart: [{ count: 1, id: 2367 }],
            review: reviewText,
          })
          .then((r) => {
            // context.commit(SET_ORDER_ID, r.data.id);
            resolve(r.data);
          })
          .catch((r) => {
            reject(r);
          });
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(errors);
      }
    });
  },
  [CREATE_QUESTION](context, questionText) {
    return new Promise((resolve, reject) => {
      let phone = context.getters.user.phone.replaceAll(/[-\s()]/g, "");
      let errors = [];
      if (questionText.length < 3) errors.push("text");
      if (phone.length < 10) errors.push("phone");

      if (errors.length === 0) {
        api
          .post("/question.create", {
            user: { ...context.getters.user, phone },
            cart: [{ count: 1, id: 2367 }],
            question: questionText,
          })
          .then((r) => {
            // context.commit(SET_ORDER_ID, r.data.id);
            resolve(r.data);
          })
          .catch((r) => {
            reject(r);
          });
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(errors);
      }
    });
  },
  [GET_BOOK](context, email) {
    return new Promise((resolve, reject) => {
      api
        .post("/request.book", {
          user: { ...context.getters.user },
          email: email,
        })
        .then((r) => {
          // context.commit(SET_ORDER_ID, r.data.id);
          resolve(r.data);
        })
        .catch((r) => {
          reject(r);
        });
    });
  },
  [GET_COORDINATES](context) {
    return new Promise((resolve, reject) => {
      api
        .post("/get.coords", {
          ip: context.getters.user.ip,
        })
        .then((r) => {
          // context.commit(SET_ORDER_ID, r.data.id);
          resolve(r.data);
        })
        .catch((r) => {
          reject(r);
        });
    });
  },
  // [CHECK_GIFTS](context, giftArray) {
  //     return new Promise((resolve) => {
  //         resolve(
  //             giftArray.every((r) => context.getters.cartIds.includes(r))
  //         );
  //     });
  // },
  [ACTIVATE_GIFTS](context) {
    let cartIds = context.getters.cart.map((v) => {
      return v.id;
    });
    context.getters.gifts.forEach((value) => {
      let requiredItems = value.conditions
        .filter((f) => f.type === "item")
        .map((i) => {
          return i.id;
        });
      let activeGift = false;
      if (value.any) {
        activeGift = requiredItems.some((r) => cartIds.includes(r));
      } else {
        activeGift = requiredItems.every((r) => cartIds.includes(r));
      }
      if (activeGift) {
        context.dispatch(
          TOGGLE_ITEM,
          context.getters.items.find((v) => v.id === value.id)
        );
      }
    });
  },
  [SET_PROMO](context, promo) {
    return new Promise((resolve) => {
      const token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzM1MzVhZjU2NjI0MjkxNTZlM2M0NGM1NTA0NzhiOWI2ZDFhODUzMjFmOGZlZjMzODIwZjk3MzhhMDg4NzQ1MjVhNDU4MGU2ODkzMmVkMTUiLCJpYXQiOjE2NjM5MTYwMjMsIm5iZiI6MTY2MzkxNjAyMywiZXhwIjoxNjk1NDUyMDIzLCJzdWIiOiI0Iiwic2NvcGVzIjpbXX0.fys5NXY3IhegvskBiZNsBKkb04roS6MAJTKTCnrDu5TGzxw2s7n8Xz9Tlgvy81iTQNC-dj6RUAJxy23kuZwK7A0wQ6RhbYtSg4-VQgG0wztPJcujNvhSm3C8JpVIUw27l8y8H8op-Abu0QiHEvu0OXsNNKwt6NrYFXDv-kHxv2hzd0zcFjSouBiJihFhWPsbZbSN5B7ED-whkafW5hZsZ9_4MD9NT7xxGk584xP9sF9bt_hxjQ5tfGrMvs2-ePpqy_UR9N5VU686Yp0buBZqnMNvdl31m827OuqnUc-mBqdXPjcA42jSRfPDXiDUNQVEk1rjfzLhsEMQVzcnPSWGZNGtxEogV2U78XRKJ-nKmw2V5Atg1sXsDCtX8MGbfNgxlpcPMfTRHux_tkJ5k9rvSEzV_Ah3otS2FVt4yzfbdwjopHTDdjVfDGgWwG9a72tPemy7GS15DBrmIvIaPfDJ_lGzOlmtOnaIOWu6grfZ1lS97vJodpNSKEUsc-RhZjAl6doPrvr2Asy0z3GK1l0hjAfAGYlafIDU-UlphCZpb5iFdLes-nwjSYaxt1ehpT0_C6D00NIo2tdlJstMW-Ptbi252k8Sd2rreBSRwldoXhbjucwUxyl3FqtQOXlaPlAOZt0kzTxWoYQbgFtT8VsCNo-SdVjXsGI4D8S-Lvu23RQ";

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      api
        .post(
          "https://api.lx9.ru/api/promo",
          {
            code: promo,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (Object.keys(response.data).length > 0) {
            // if (response.data.discount.search("%") != -1) {
            //     self.order_discount =
            //         parseInt(self.order_price) *
            //         (parseInt(response.data.discount) / 100);
            // } else {
            //     self.order_discount = parseInt(
            //         response.data.discount
            //     );
            // }
            // context.commit(
            //     SET_PROMO_DISCOUNT,
            //     response.data.discount
            // );
            let required_ids = [];
            let gift_ids = [];

            response.data.gifts.forEach((v) => {
              gift_ids.push(v.id);
              // self.switchSelect(
              //     self.getItemById(v.id),
              //     true,
              //     false,
              //     true
              // );

              context.commit(ADD_GIFTS_DATA, {
                id: v.id,
                promo: promo,
                conditions: response.data.require_items.map((v) => ({
                  type: "item",
                  id: v.id,
                })),
                any: response.data.any_item,
              });
            });

            response.data.require_items.forEach((v) => {
              required_ids.push(v.id);
            });

            context.commit(SET_PROMO_DATA, {
              id: response.data.id,
              code: promo,
              discount: 0,
              gift_items: response.data.gifts,
              gift_ids: gift_ids,
              required_items: required_ids,
              any: parseInt(response.data.any_item),
            });
            context.dispatch(ACTIVATE_GIFTS);
          }

          let cardIds = context.getters.cart.map((v) => {
            return v.id;
          });

          let usedKit = false;
          let cartForSent = context.getters.cart;
          context.getters.kits.forEach((value) => {
            if (!usedKit) {
              let activeKit = value.items.every((r) => cardIds.includes(r));
              if (activeKit) {
                usedKit = true;

                cartForSent = cartForSent.filter(
                  (v) => !value.items.includes(v.id)
                );

                cartForSent.push({
                  id: value.id,
                  count: 1,
                });
              }
            }
          });

          context.getters.gifts.forEach((value) => {
            let requiredItems = value.conditions
              .filter((f) => f.type === "item")
              .map((i) => {
                return i.id;
              });
            let activeGift = false;
            if (value.any) {
              activeGift = requiredItems.some((r) =>
                cartForSent.map((v) => v.id).includes(r)
              );
            } else {
              activeGift = requiredItems.every((r) =>
                cartForSent.map((v) => v.id).includes(r)
              );
            }
            console.log(activeGift);
            if (activeGift) {
              // context.dispatch(TOGGLE_ITEM, {
              //   ...context.getters.items.find((v) => v.id === value.id),
              //   force: true,
              // });
              context.dispatch(
                SET_ACTIVE,
                context.getters.items.find((v) => v.id === value.id)
              );
            }
          });
          resolve();
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit(SET_PROMO_CODE, promo);
      resolve();
    });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
